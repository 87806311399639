import { Card } from 'antd';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { TUserService } from '../start-new-task/type';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const Wrapper = styled.div`
    .ant-card-body {
        display: flex;
        flex-direction: column;
        padding: 16px;
    }
    .actions {
        position: absolute;
        right: 15px;
        top: 15px;
        .action-container {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

type MobileListUserServicesPageProps = {
    userServiceList: TUserService[];
    onEditUserService: (item: TUserService) => void;
    onDeleteUserService: (item: TUserService) => void;
};

export const MobileListUserServicesPage: React.FC<
    MobileListUserServicesPageProps
> = ({ userServiceList, onEditUserService, onDeleteUserService }) => {
    return (
        <Wrapper className="flex flex-col gap-[15px] px-1 mb-[80px] relative">
            {userServiceList.map(item => {
                return (
                    <Card
                        key={item.userId}
                        className={classNames([
                            'relative flex flex-col cursor-pointer rounded-[12px] max-md:hover:ring-0 hover:ring-2 hover:ring-[var(--primaryColor)] shadow-card-shadow',
                        ])}
                    >
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <div className="ml-2 flex gap-1 flex-col">
                                    <div className="text-lg font-bold text-high-em line-clamp-1">
                                        {item.username}
                                    </div>
                                    <div className="text-sm text-high-em">
                                        {item.email}
                                    </div>
                                    <div className="text-sm text-low-em">
                                        {item.roles
                                            ?.map(role => role)
                                            .join(', ')}
                                    </div>
                                </div>
                            </div>
                            <div className="flex actions gap-[15px]">
                                <div
                                    className="action-container gap-2 cursor-pointer shadow-big-shadow"
                                    onClick={() => {
                                        onEditUserService(item);
                                    }}
                                >
                                    <EditOutlined />
                                </div>
                                <div
                                    className="action-container bg-red-500 text-white cursor-pointer shadow-big-shadow"
                                    onClick={() => {
                                        onDeleteUserService(item);
                                    }}
                                >
                                    <DeleteOutlined />
                                </div>
                            </div>
                        </div>
                    </Card>
                );
            })}
        </Wrapper>
    );
};
