import ReactDOM from 'react-dom/client';
import './tailwind.css';
import './variables.css';
import './index.css';
import './fonts.css';

// mock data
import './mock';

import { Provider } from 'react-redux';
import store from './redux/store';
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from 'react-router-dom';
import { NotFoundPage } from './pages/404';
import { Loading } from './components/loading';
import { Unauthorized } from './pages/unauthorized';
import { GeneralPage } from './pages/general';
import { AllTasksPage } from './pages/all-tasks';
import { ConfigProvider, ThemeConfig } from 'antd';
import { LoginPage } from './pages/auth/login';
import { LayoutType } from './utils/enums';
import { StartNewTaskPage } from './pages/start-new-task';
import { CarsPage } from './pages/cars';
import { ServicesPage } from './pages/services';
import { UserServicesPage } from './pages/user-service';
import { MyToDoListPage } from './pages/todo-list';
import { RegisterPage } from './pages/auth/register';
import { registerWorker } from './firebase/registerWorker';
import { AddReceiptsPage } from './pages/add-receipts';
import { ChangePasswordPage } from './pages/auth/change-password';

const config: ThemeConfig = {
    hashed: false,
    token: {
        fontFamily: 'Roboto, sans-serif',
        colorPrimary: '#7357FF',
    },
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: '/',
        element: <Navigate to="/todo-list" />,
    },
    {
        path: '/login',
        element: <Unauthorized page={<LoginPage />} />,
    },
    {
        path: '/register',
        element: <Unauthorized page={<RegisterPage />} />,
    },
    {
        path: '/change-password/:email',
        element: <Unauthorized page={<ChangePasswordPage />} />,
    },
    {
        path: '/tasks',
        element: (
            <GeneralPage
                layout={LayoutType.ADMIN}
                page={<AllTasksPage />}
                isPrivate
            />
        ),
        handle: {
            info: {
                title: 'All Tasks',
                description: 'Welcome to the All Tasks page',
            },
            crumbs: [
                {
                    title: 'Home',
                    href: '/',
                },
            ],
        },
    },
    {
        path: '/todo-list',
        element: (
            <GeneralPage
                layout={LayoutType.ADMIN}
                page={<MyToDoListPage />}
                isPrivate
            />
        ),
        handle: {
            info: {
                title: 'My To-Do List',
                description: 'Welcome to the My To-Do List page',
            },
            crumbs: [
                {
                    title: 'Home',
                    href: '/',
                },
            ],
        },
    },
    {
        path: '/start-new-task',
        element: (
            <GeneralPage
                layout={LayoutType.ADMIN}
                page={<StartNewTaskPage />}
                isPrivate
            />
        ),
        handle: {
            info: {
                title: 'New Task',
                description: 'Welcome to the New Task page',
            },
            crumbs: [
                {
                    title: 'Home',
                    href: '/',
                },
            ],
        },
    },
    {
        path: '/add-receipts',
        element: (
            <GeneralPage
                layout={LayoutType.ADMIN}
                page={<AddReceiptsPage />}
                isPrivate
            />
        ),
        handle: {
            info: {
                title: 'Add Receipt',
                description: 'Welcome to the Add Receipt page',
            },
            crumbs: [
                {
                    title: 'Home',
                    href: '/',
                },
            ],
        },
    },
    {
        path: '/settings/cars',
        element: (
            <GeneralPage
                layout={LayoutType.ADMIN}
                page={<CarsPage />}
                isPrivate
            />
        ),
        handle: {
            info: {
                title: 'Cars',
                description: 'Welcome to the Cars page',
            },
            crumbs: [
                {
                    title: 'Home',
                    href: '/',
                },
            ],
        },
    },
    {
        path: '/settings/services',
        element: (
            <GeneralPage
                layout={LayoutType.ADMIN}
                page={<ServicesPage />}
                isPrivate
            />
        ),
        handle: {
            info: {
                title: 'Services',
                description: 'Welcome to the Services page',
            },
            crumbs: [
                {
                    title: 'Home',
                    href: '/',
                },
            ],
        },
    },
    {
        path: '/settings/user-service',
        element: (
            <GeneralPage
                layout={LayoutType.ADMIN}
                page={<UserServicesPage />}
                isPrivate
            />
        ),
        handle: {
            info: {
                title: 'Users',
                description: 'Welcome to the User Services page',
            },
            crumbs: [
                {
                    title: 'Home',
                    href: '/',
                },
            ],
        },
    },
    {
        path: '*',
        element: <NotFoundPage />,
    },
]);

root.render(
    <ConfigProvider theme={config}>
        <Provider store={store}>
            <div className="App">
                <RouterProvider router={router} />
                <Loading />
            </div>
        </Provider>
    </ConfigProvider>
);

// register service worker
registerWorker();
