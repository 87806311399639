import React from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import {
    EStatusOfService,
    TCarInService,
    TServiceDetail,
} from '../../start-new-task/type';
import { TaskItemPage } from '../../all-tasks/task-item';
import styled from 'styled-components';
import { Spin } from 'antd';

const Wrapper = styled.div`
    &.infinite-scroll-container > div:first-child {
        width: 100% !important;
    }
`;

type TaskItemPageProps = {
    taskList: TCarInService[];
    hasLoadMore: boolean;
    mobileMode: boolean;
    setCarInServiceSelected: React.Dispatch<
        React.SetStateAction<TCarInService | undefined>
    >;
    setOpenEditTask: React.Dispatch<React.SetStateAction<boolean>>;
    onUpdateStatus: (
        status: EStatusOfService,
        serviceSelection: TServiceDetail,
        taskSelected: TCarInService | undefined
    ) => void;
    onLoadMore: () => void;
    onReloadData: () => void;
};

export const TaskListPage: React.FC<TaskItemPageProps> = ({
    taskList,
    hasLoadMore,
    mobileMode,
    setCarInServiceSelected,
    setOpenEditTask,
    onUpdateStatus,
    onLoadMore,
    onReloadData,
}) => {
    const contentStyle: React.CSSProperties = {
        padding: 50,
        background: 'rgba(0, 0, 0, 0.05)',
        borderRadius: 4,
    };
    const contentLoading = <div style={contentStyle} />;

    return (
        <Wrapper className="flex w-full h-full infinite-scroll-container">
            <InfiniteScroll
                dataLength={taskList.length}
                next={onLoadMore}
                hasMore={hasLoadMore}
                style={{ width: '100%' }}
                loader={
                    <div className="text-center">
                        <Spin tip="Loading...">{contentLoading}</Spin>
                    </div>
                }
                scrollableTarget="scrollableDiv"
            >
                <div className="flex flex-col h-full w-full max-md:pt-0 max-md:px-0 px-[1.5rem] pt-[1rem] pb-[5px]">
                    <div className="grid max-md:grid-cols-1 grid-cols-3 gap-[15px] flex-wrap">
                        {taskList.map((task, index) => (
                            <TaskItemPage
                                key={index}
                                task={task}
                                mobileMode={mobileMode}
                                setCarInServiceSelected={
                                    setCarInServiceSelected
                                }
                                setOpenEditTask={setOpenEditTask}
                                onUpdateStatus={onUpdateStatus}
                                onReload={onReloadData}
                            />
                        ))}
                    </div>
                </div>
            </InfiniteScroll>
        </Wrapper>
    );
};
