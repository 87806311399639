import React from 'react';
import { AutoTrackButton } from '../../../components/button';
import { ICCamera } from '../../../icons';
import styled from 'styled-components';
import { Drawer } from 'antd';
import { CameraControl } from '../../add-receipts/components/camera-control';
import Webcam from 'react-webcam';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { readImageOCR } from '../../../utils/ocrUtils';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/slices/appInfo';
import { useNotification } from '../../../hooks/useNotification';
import { ScannerType } from '../../add-receipts/components/scanner-overlay';

const DrawerBottom = styled(Drawer)`
    .ant-drawer-body {
        padding: 0;
    }
`;

type CameraOCRProps = {
    scannerType: ScannerType;
    onSelectedTextImage?: (val: any) => void;
};

export const CameraOCR: React.FC<CameraOCRProps> = ({
    scannerType,
    onSelectedTextImage,
}) => {
    const dispatch = useDispatch();
    const { showError } = useNotification();
    const size = useScreenSize();
    const mobileMode = size === 'xs' || size === 'sm';
    const [openCamera, setOpenCamera] = React.useState(false);
    const webcamRef = React.useRef<Webcam>(null);

    const onCompletedCapture = (image: string, imageFile: File) => {
        setOpenCamera(false);
        if (imageFile) {
            readOCR(imageFile);
        }
    };

    const readOCR = React.useCallback(
        async (imageFile: File | null) => {
            if (imageFile) {
                dispatch(setLoading(true));
                try {
                    const response = await readImageOCR(imageFile);
                    const { data } = response || {};
                    const { text } = data || {};
                    // console.log('response', response);
                    if (text) {
                        onSelectedTextImage && onSelectedTextImage(text);
                    } else {
                        /* eslint-disable */
                        console.warn('readImageOCR response', response);
                        showError('Warning', 'Failed to read image');
                    }
                } catch (error) {
                    showError('Error', 'Failed to read image');
                } finally {
                    dispatch(setLoading(false));
                }
            }
        },
        [dispatch, showError]
    );

    return (
        <div className="flex w-full">
            <div className="flex flex-col items-center justify-center w-full">
                <AutoTrackButton
                    btnSize="sm"
                    classess="w-full"
                    btnType="outline"
                    style={{
                        height: '42px',
                        width: '50px',
                        borderRadius: '10px',
                        borderColor: 'var(--primaryColor)',
                        padding: '0',
                    }}
                    onClick={() => setOpenCamera(true)}
                >
                    <ICCamera
                        fill="var(--primaryColor)"
                        width={30}
                        height={30}
                    />
                </AutoTrackButton>
            </div>
            <DrawerBottom
                title="Take a photo"
                onClose={() => setOpenCamera(false)}
                open={openCamera}
                destroyOnClose
                zIndex={1000}
                width="100%"
                height="100%"
                placement="bottom"
            >
                <div className="flex h-full flex-col justify-between w-full gap-3">
                    <div className="flex w-full h-full mt-[0]">
                        <CameraControl
                            webcamRef={webcamRef}
                            isMobile={mobileMode}
                            isShowScannerOverlay={true}
                            scannerType={scannerType}
                            hasImageCompression={false}
                            onCompletedCapture={onCompletedCapture}
                        />
                    </div>
                </div>
            </DrawerBottom>
        </div>
    );
};
