import React from 'react';
import { AddServices } from '../add-services';
import { AutoTrackForm } from '../../../../components/form/form';
import { AutoTrackButton } from '../../../../components/button';
import { useCreateServices } from '../../hooks/useCreateServices';
import { useNotification } from '../../../../hooks/useNotification';
import styled from 'styled-components';

const WrapperActions = styled.div`
    @media (max-width: 768px) {
        .ant-btn {
            width: 100% !important;
        }
    }
`;

export const RepairServicesStep: React.FC = () => {
    const {
        serviceList,
        setServiceList,
        servicesSelected,
        setServicesSelected,
        repairServices,
        setRepairServices,
        userServices,
        setUserServices,
        reviewServices,
        stepTwoForm,
        onChangeStep,
        isUserRole,
        username,
    } = useCreateServices();
    const { showError } = useNotification();
    const onSaveAndNext = React.useCallback(async () => {
        try {
            await stepTwoForm.validateFields();
            if (repairServices?.length === 0) {
                showError('Error', 'Please select at least one service');
                return;
            }
            onChangeStep(2);
        } catch (error) {
            // Handle error
        }
    }, [showError, stepTwoForm, repairServices, servicesSelected]);

    React.useEffect(() => {
        if (reviewServices && reviewServices?.length > 0) {
            const services = reviewServices.map(service => {
                return {
                    name: service.name,
                    assignee: service.users.map(user => user.username),
                };
            });
            stepTwoForm.setFieldsValue({
                services,
            });
        }
    }, [reviewServices, stepTwoForm]);

    return (
        <div className="bg-white max-md:p-[16px] p-[32px] rounded-[8px]">
            <div className="flex flex-col gap-[8px]">
                <div className="text-heading-6-bold text-high-em">
                    Select services
                </div>
                <div className="text-med-em text-body">
                    Please select the select services that you want to add to
                    the system
                </div>
            </div>
            <div className="flex flex-col w-full gap-[30px]">
                <div className="flex w-full">
                    <AutoTrackForm
                        layout="vertical"
                        form={stepTwoForm}
                        style={{ width: '100%' }}
                        autoComplete="off"
                        childNode={
                            <div className="flex flex-col w-full">
                                <div className="flex w-full">
                                    <AddServices
                                        isUserRole={isUserRole}
                                        username={username}
                                        servicesSelected={servicesSelected}
                                        setServicesSelected={
                                            setServicesSelected
                                        }
                                        serviceList={serviceList}
                                        setServiceList={setServiceList}
                                        userServices={userServices}
                                        setUserServices={setUserServices}
                                        repairServices={repairServices}
                                        setRepairServices={setRepairServices}
                                    />
                                </div>
                            </div>
                        }
                    />
                </div>
                <WrapperActions className="flex max-md:grid max-md:grid-cols-2 justify-start gap-[15px]">
                    <span className="max-md:w-full">
                        <AutoTrackButton
                            type="primary"
                            btnSize="md"
                            btnType="outline"
                            classess="max-md:w-full"
                            style={{ width: '100px' }}
                            onClick={() => onChangeStep(0)}
                        >
                            Back
                        </AutoTrackButton>
                    </span>
                    <span className="max-md:w-full">
                        <AutoTrackButton
                            type="primary"
                            htmlType="submit"
                            btnSize="md"
                            classess="max-md:w-full"
                            btnType="primary"
                            style={{ width: '100px' }}
                            onClick={onSaveAndNext}
                        >
                            Save & Next
                        </AutoTrackButton>
                    </span>
                </WrapperActions>
            </div>
        </div>
    );
};
