import { Image } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import { TImage } from '../../api/image-service/type';
import { setLoading } from '../../redux/slices/appInfo';
import { getDownloadURL, ref } from 'firebase/storage';
import { storageFirebase } from '../../firebase/firebaseConfig';

type ImageListControlProps = {
    imageList: TImage[];
    isMobileMode: boolean;
};
export const ImageListControl: React.FC<ImageListControlProps> = ({
    imageList,
    isMobileMode,
}) => {
    const dispatch = useDispatch();
    const [imageUrlList, setImageUrlList] = React.useState<TImage[]>([]);

    const convertImageUrl = async (img: TImage) => {
        const imageRef = ref(storageFirebase, img.imageUrl);
        const url = await getDownloadURL(imageRef);
        return url;
    };

    const parseImageUrlList = async (imageList: TImage[]) => {
        const result = await Promise.all(
            imageList.map(async img => {
                const url = await convertImageUrl(img);
                return {
                    ...img,
                    url: url,
                };
            })
        );
        return result;
    };

    React.useEffect(() => {
        if (imageList?.length > 0) {
            dispatch(setLoading(true));
            parseImageUrlList(imageList).then(data => {
                dispatch(setLoading(false));
                setImageUrlList(data);
            });
        }
    }, [imageList]);

    return (
        <div className="flex flex-col gap-[20px] w-full overflow-auto px-3 h-full items-start box-border">
            <div
                className={classNames([
                    'grid flex-wrap gap-[20px] w-full',
                    {
                        'grid-cols-1': isMobileMode,
                        'grid-cols-2': !isMobileMode,
                    },
                ])}
            >
                <Image.PreviewGroup>
                    {imageUrlList.map((img, index) => {
                        if (!img.url)
                            return (
                                <div
                                    key={index}
                                    className={classNames([
                                        'flex justify-center items-center',
                                        'w-full h-full',
                                        'text-white text-lg font-bold',
                                        'bg-black bg-opacity-50',
                                        'rounded-lg',
                                        {
                                            'h-20': isMobileMode,
                                            'h-40': !isMobileMode,
                                        },
                                    ])}
                                >
                                    Loading...
                                </div>
                            );
                        return (
                            <Image
                                key={index}
                                width="100%"
                                height={isMobileMode ? 100 : 200}
                                loading="lazy"
                                style={{
                                    objectFit: 'cover',
                                    borderRadius: '12px',
                                    border: '1px solid var(--text-low-em)',
                                    padding: '5px',
                                }}
                                preview={{
                                    mask: (
                                        <div className="bg-black bg-opacity-50" />
                                    ),
                                }}
                                src={img.url}
                                alt={img.fileName}
                            />
                        );
                    })}
                </Image.PreviewGroup>
            </div>
        </div>
    );
};
