import Webcam from 'react-webcam';
import styled from 'styled-components';
import React from 'react';
import {
    ICCamera,
    ICImage,
    ICSwitchCamera,
    ICWarning,
} from '../../../../icons';
import imageCompression from 'browser-image-compression';
import { useNotification } from '../../../../hooks/useNotification';
import { ScannerOverlay, ScannerType } from '../scanner-overlay';
import { getCroppedImg } from '../../../../utils/ocrUtils';
// import { saveAs } from 'file-saver';

const WrapperCamera = styled.div`
    video {
        height: 100%;
        object-fit: cover;
    }
`;
const WrapperSwitchCamera = styled.div`
    background-color: rgba(255, 255, 255, 0.5);
`;

export const SCAN_SIZE = {
    VIN: {
        width: 400,
        height: 120,
    },
    PLATE: {
        width: 320,
        height: 160,
    },
};

type CameraControlProps = {
    webcamRef: React.RefObject<Webcam>;
    isMobile: boolean;
    isShowScannerOverlay: boolean;
    hasImageCompression: boolean;
    scannerType: ScannerType;
    onCompletedCapture: (image: string, imageFile: File) => void;
};
export const CameraControl: React.FC<CameraControlProps> = ({
    hasImageCompression,
    isShowScannerOverlay,
    webcamRef,
    isMobile,
    scannerType,
    onCompletedCapture,
}) => {
    const { showError } = useNotification();
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const FACING_MODE_USER = 'user';
    const FACING_MODE_ENVIRONMENT = 'environment';
    const [facingMode, setFacingMode] = React.useState<string>(
        FACING_MODE_ENVIRONMENT
    );
    const videoConstraints = React.useMemo(() => {
        return {
            facingMode: facingMode,
            width: window.innerWidth,
            height: window.innerHeight,
        };
    }, [isMobile, facingMode]);

    const [isNotSupportCamera, setIsNotSupportCamera] =
        React.useState<boolean>(false);

    const stopCamera = () => {
        if (webcamRef?.current) {
            const streamVideo = webcamRef?.current?.video
                ?.srcObject as MediaStream;
            if (streamVideo) {
                streamVideo.getTracks()?.forEach((track: any) => track?.stop());
            }
        }
    };

    React.useEffect(() => {
        return () => {
            stopCamera();
        };
    }, []);

    const onUserMediaError = React.useCallback(() => {
        setIsNotSupportCamera(true);
    }, []);

    React.useEffect(() => {
        return () => {
            stopCamera();
        };
    }, []);

    const onCapture = React.useCallback(async () => {
        if (webcamRef.current) {
            const TARGET_WIDTH = SCAN_SIZE[scannerType].width;
            const TARGET_HEIGHT = SCAN_SIZE[scannerType].height;
            const imageSrc = webcamRef.current.getScreenshot();
            if (imageSrc) {
                try {
                    if (isShowScannerOverlay) {
                        const croppedImage = await getCroppedImg(
                            imageSrc,
                            TARGET_WIDTH,
                            TARGET_HEIGHT
                        );
                        const blob = await fetch(croppedImage).then(r =>
                            r.blob()
                        );
                        const fileAfterCrop = new File([blob], 'cropped.jpg', {
                            type: 'image/jpeg',
                        });
                        onCompletedCapture(croppedImage, fileAfterCrop);
                        // test save image
                        // saveAs(fileAfterCrop, 'captured_image.jpg');
                    } else {
                        if (hasImageCompression) {
                            const newFile = await compressImage(imageSrc);
                            const newImageUrl = URL.createObjectURL(newFile);
                            onCompletedCapture(newImageUrl, newFile);
                        } else {
                            const newFile = await dataStringToFile(imageSrc);
                            onCompletedCapture(imageSrc, newFile);
                        }
                    }
                } catch (error) {
                    // TODO: handle error
                }
            }
        }
    }, [webcamRef, isShowScannerOverlay, hasImageCompression, scannerType]);

    const dataStringToFile = async (dataString: string): Promise<File> => {
        const response = await fetch(dataString);
        const blob = await response.blob();
        const file = new File([blob], 'captured_image.jpg', {
            type: 'image/jpeg',
        });
        return file;
    };

    const compressImage = async (imageSrc: string): Promise<File> => {
        const response = await fetch(imageSrc);
        const blob = await response.blob();
        const file = new File([blob], 'captured_image.jpg', {
            type: 'image/jpeg',
        });
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            useWebWorker: true,
        };

        return await imageCompression(file, options);
    };

    const triggerFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const onSubmitFile = async (file: File) => {
        if (file) {
            try {
                const imageUrl = URL.createObjectURL(file);
                const newFile = await compressImage(imageUrl);
                const newImageUrl = URL.createObjectURL(newFile);
                onCompletedCapture(newImageUrl, newFile);
            } catch (error) {
                showError('Error', 'Failed to read image');
            }
        }
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files || [];
        if (selectedFiles.length === 0) {
            return;
        }
        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            if (file.size > 5 * 1024 * 1024) {
                showError('Error', 'File size should be less than 5MB');
                continue;
            }
            onSubmitFile(file);
        }
    };

    return (
        <WrapperCamera className="relative flex flex-col w-full h-full">
            <div className="relative flex flex-col items-center h-full w-full">
                <Webcam
                    ref={webcamRef}
                    audio={false}
                    style={{ width: '100%', height: '100%' }}
                    videoConstraints={videoConstraints}
                    screenshotFormat="image/jpeg"
                    onUserMediaError={onUserMediaError}
                    forceScreenshotSourceSize={true}
                    onUserMedia={() => {
                        setIsNotSupportCamera(false);
                    }}
                />
                {isShowScannerOverlay && (
                    <ScannerOverlay scannerType={scannerType} />
                )}
                <div className="absolute bottom-[10px] left-[20px] p-2">
                    <WrapperSwitchCamera
                        onClick={triggerFileInput}
                        className="flex rounded-[50%] h-[35px] w-[35px] items-center justify-center"
                    >
                        <div className="flex flex-col p-[10px] items-center justify-center w-full gap-2">
                            <ICImage fill="white" width={20} height={20} />
                        </div>
                    </WrapperSwitchCamera>
                    <input
                        type="file"
                        multiple
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                        accept="image/*"
                    />
                </div>
                <div className="absolute flex items-center justify-center bottom-[20px]">
                    <WrapperSwitchCamera
                        onClick={onCapture}
                        className="flex rounded-[50%] h-[50px] w-[50px] items-center justify-center cursor-pointer"
                    >
                        <ICCamera fill="white" />
                    </WrapperSwitchCamera>
                </div>
                <div className="absolute bottom-[10px] right-[20px] p-2">
                    <WrapperSwitchCamera
                        className="flex rounded-[50%] h-[35px] w-[35px] items-center justify-center"
                        onClick={() => {
                            setFacingMode(
                                facingMode === FACING_MODE_USER
                                    ? FACING_MODE_ENVIRONMENT
                                    : FACING_MODE_USER
                            );
                        }}
                    >
                        <ICSwitchCamera />
                    </WrapperSwitchCamera>
                </div>
                {isNotSupportCamera && (
                    <div className="flex flex-col items-center justify-center h-full absolute top-[0] w-full gap-[8px] bg-surface-high rounded-[12px] not-support-camera">
                        <span>
                            <ICWarning fill="#C6C5CA" />
                        </span>
                        <span className="text-body-medium text-high-em">
                            Camera not supported
                        </span>
                    </div>
                )}
            </div>
        </WrapperCamera>
    );
};
