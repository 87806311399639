import { Form, Select } from 'antd';
import React from 'react';
import { FormInstance } from 'antd/lib/form/Form';
import styled from 'styled-components';
import dayjs from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import { TUserService } from '../start-new-task/type';
import { AutoTrackForm } from '../../components/form/form';
import {
    AutoTrackInput,
    AutoTrackInputPassword,
} from '../../components/form/input';
import { ROLES_OPTIONS } from '../../utils/constants';
import { PhoneControl } from './phone';
import { validatePasswordStrength } from '../../utils/passwordValidator';

dayjs.extend(customParseFormat);

const Wrapper = styled.div`
    .ant-row {
        align-items: center;
    }
    .ant-form-item-has-error {
        .ant-row {
            align-items: flex-start;
        }
    }
    @media (max-width: 768px) {
        .ant-form-item .ant-form-item-label {
            flex: 0 0 100% !important;
            padding-bottom: 0px;
        }
    }
`;

type UserServiceFormProps = {
    userServiceData: TUserService | undefined;
    phoneNumbers: string[];
    setPhoneNumbers: React.Dispatch<React.SetStateAction<string[]>>;
    form: FormInstance;
};
export const UserServiceForm: React.FC<UserServiceFormProps> = ({
    userServiceData,
    form,
    phoneNumbers,
    setPhoneNumbers,
}) => {
    const childForm = (
        <div className="grid gap-[10px] grid-cols-[1fr] max-md:p-[10px] max-md:pb-0">
            <Form.Item
                name="username"
                label="Name"
                rules={[
                    {
                        required: true,
                        message: 'Please input the name!',
                    },
                ]}
            >
                <AutoTrackInput isRequired={true} />
            </Form.Item>
            <Form.Item
                name="email"
                label="Email"
                rules={[
                    {
                        required: true,
                        message: 'Please input the email!',
                    },
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                ]}
            >
                <AutoTrackInput isRequired={true} />
            </Form.Item>
            <Form.Item
                name="passwordHash"
                label="Password"
                rules={[
                    {
                        required: true,
                        message: 'Please input the password!',
                    },
                    {
                        validator: (_, value) => {
                            const { isValid } = validatePasswordStrength(value);
                            if (isValid) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error('Password does not strong enough')
                            );
                        },
                    },
                ]}
            >
                <AutoTrackInputPassword
                    size="large"
                    maxLength={20}
                    placeholder="Password"
                />
            </Form.Item>
            <Form.Item
                name="roles"
                label="Roles"
                rules={[
                    {
                        required: true,
                        message: 'Please input the roles!',
                    },
                ]}
            >
                <Select
                    mode="multiple"
                    size="large"
                    placeholder="Please select"
                    style={{ width: '100%' }}
                    options={ROLES_OPTIONS}
                />
            </Form.Item>
            <PhoneControl
                phoneNumbers={phoneNumbers}
                setPhoneNumbers={setPhoneNumbers}
            />
        </div>
    );

    React.useEffect(() => {
        if (userServiceData) {
            form.setFieldsValue({
                username: userServiceData.username,
                email: userServiceData.email,
                passwordHash: userServiceData.passwordHash,
                roles: userServiceData.roles ?? [],
                phoneNumber: userServiceData.phoneNumber ?? [],
            });
        }
    }, [userServiceData, form]);

    return (
        <Wrapper className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border py-[20px] px-[5px] max-md:pt-0 max-md:px-0">
            <AutoTrackForm
                labelCol={{ flex: '130px' }}
                labelAlign="left"
                form={form}
                labelWrap
                wrapperCol={{ flex: 1 }}
                layout="horizontal"
                className="w-full"
                name="createUserService"
                childNode={childForm}
            />
        </Wrapper>
    );
};
