import React from 'react';
import { CreateServicesDataContext } from './hooks/useCreateServices';
import { STEP, TStepItemProps } from './config';
import classNames from 'classnames';
import { useForm } from 'antd/lib/form/Form';
import { CreateServicesStep } from './step';
import { TCar, TRepairService, TService, TUserService } from './type';
import { useNotification } from '../../hooks/useNotification';
import { useScreenSize } from '../../hooks/useScreenSize';
import { RootState } from '../../redux/reducers';
import { useSelector } from 'react-redux';
import { Role } from '../../utils/constants';

export const StartNewTaskPage: React.FC = () => {
    const [step, setStep] = React.useState<number>(0);
    const [stepServices, setStepServices] =
        React.useState<TStepItemProps[]>(STEP);
    const [addLicensePlateForm] = useForm();
    const [selectLicensePlateForm] = useForm();
    const [stepTwoForm] = useForm();
    const { showError } = useNotification();
    const size = useScreenSize();
    const mobileMode = size === 'xs' || size === 'sm';
    const userInfo = useSelector((state: RootState) => state.userInfo);

    const [carList, setCarList] = React.useState<TCar[]>([]);
    const [carSelected, setCarSelected] = React.useState<TCar | undefined>();
    const [serviceList, setServiceList] = React.useState<
        TService[] | undefined
    >([]);
    const [servicesSelected, setServicesSelected] = React.useState<
        TService[] | undefined
    >([]);

    const [userServices, setUserServices] = React.useState<
        TUserService[] | undefined
    >([]);
    const [repairServices, setRepairServices] = React.useState<
        TRepairService[] | undefined
    >([]);
    const [reviewServices, setReviewServices] = React.useState<
        TRepairService[] | undefined
    >([]);
    const [dateTimeSelection, setDateTimeSelection] =
        React.useState<string>('');

    const isUserRole = React.useMemo(() => {
        const roles = userInfo?.roles || [];
        return !roles.includes(Role.MANAGER);
    }, [userInfo]);

    const onChangeStep = React.useCallback(
        async (step: number) => {
            try {
                if (step === 0) {
                    setStep(step);
                }
                if (step === 1) {
                    await selectLicensePlateForm.validateFields();
                    const values = selectLicensePlateForm.getFieldsValue();
                    const carSelection = carList.find(
                        car => car.licensePlate === values.cardId
                    );
                    if (carSelection) {
                        setCarSelected(carSelection);
                    }
                    setStep(step);
                }
                if (step === 2) {
                    await selectLicensePlateForm.validateFields();
                    await stepTwoForm.validateFields();
                    if (repairServices?.length === 0) {
                        showError(
                            'Error',
                            'Please select at least one service'
                        );
                        return;
                    }
                    setReviewServices(repairServices);
                    setStep(step);
                }
            } catch (error) {
                // Handle error
            }
        },
        [
            showError,
            carList,
            selectLicensePlateForm,
            stepTwoForm,
            repairServices,
        ]
    );

    return (
        <CreateServicesDataContext.Provider
            value={{
                isUserRole,
                username: userInfo?.username || '',
                step,
                setStep,
                onChangeStep,
                stepServices,
                setStepServices,
                addLicensePlateForm,
                selectLicensePlateForm,
                stepTwoForm,

                carList,
                setCarList,
                carSelected,
                setCarSelected,

                serviceList,
                setServiceList,
                servicesSelected,
                setServicesSelected,

                userServices,
                setUserServices,

                repairServices,
                setRepairServices,
                reviewServices,
                setReviewServices,
                dateTimeSelection,
                setDateTimeSelection,
            }}
        >
            <div className="flex flex-col w-full">
                <div className={classNames('grid grid-cols-1 gap-[20px]')}>
                    <CreateServicesStep mobileMode={mobileMode} />
                    <div className="my-[6px] rounded-[8px] min-h-[211px]">
                        {STEP[step].component}
                    </div>
                </div>
            </div>
        </CreateServicesDataContext.Provider>
    );
};
