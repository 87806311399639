import { ApiInstance } from '../..';
import { SearchQueryParams } from '../../../interface/paging';
import { TCar } from '../../../pages/start-new-task/type';
import { convert2QueryString } from '../../../utils/convert2QueryString';

export const getCars = () => {
    return ApiInstance.getInstance().get('/api/cars');
};

export const getCarsPaging = (params: SearchQueryParams | undefined) => {
    const requestURL = '/api/cars/search?' + convert2QueryString(params);
    return ApiInstance.getInstance().get(requestURL);
};

export const createCar = (data: TCar) => {
    return ApiInstance.getInstance().post('/api/cars/add', data);
};

export const updateCar = (carId: string | undefined | number, data: TCar) => {
    const baseUrl = `/api/cars/update/${carId}`;
    return ApiInstance.getInstance().put(baseUrl, data);
};

export const deleteCar = (carId: string | undefined | number) => {
    const baseUrl = `/api/cars/${carId}`;
    return ApiInstance.getInstance().delete(baseUrl);
};

export const setDefaultImageCar = (
    carId: number | string,
    carImageId: string | number
) => {
    return ApiInstance.getInstance().put(
        `/api/cars/setImage/${carId}/${carImageId}`
    );
};
