import { ApiInstance } from '..';
import { LoginParams } from '../../interface/user/login';

export const login = (data: LoginParams) => {
    return ApiInstance.getInstance().post('/user/login/mock', data);
};

export const logout = (token: string) => {
    return ApiInstance.getInstance().post('/user/logout/mock', { token });
};

export const checkEmailExists = (email: string) => {
    return ApiInstance.getInstance().get(`/api/public/exists/${email}`);
};
