interface UploadProgress {
    loaded: number;
    total: number;
    percentage: number;
}

export const uploadFile = (
    url: string,
    file: File,
    onProgress: (progress: UploadProgress) => void
): Promise<any> => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const formData = new FormData();

        formData.append('file', file);
        xhr.upload.onprogress = event => {
            if (event.lengthComputable) {
                const progress = {
                    loaded: event.loaded,
                    total: event.total,
                    percentage: Math.round((event.loaded * 100) / event.total),
                };
                onProgress(progress);
            }
        };

        xhr.addEventListener('load', () => {
            if (xhr.status === 200) {
                try {
                    const response = JSON.parse(xhr.responseText);
                    resolve(response);
                } catch {
                    resolve(xhr.responseText);
                }
            } else {
                reject(new Error(`Upload failed with status ${xhr.status}`));
            }
        });

        xhr.addEventListener('error', () => {
            reject(new Error('Upload failed'));
        });

        xhr.open('POST', url, true);

        // Add authorization header if needed
        const token = localStorage.getItem('token');
        if (token) {
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        }

        xhr.send(formData);
    });
};
