import React from 'react';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { RowService } from './row-service';
import { useDispatch } from 'react-redux';
import { useNotification } from '../../../hooks/useNotification';
import { removeUndefinedAttribute, reorder } from '../../../utils';
import {
    EUpdateType,
    TCarInService,
    TCarInServiceRequest,
    TService,
    TServiceDetail,
    TServiceRequest,
    TUserService,
} from '../../start-new-task/type';
import { setLoading } from '../../../redux/slices/appInfo';
import { AutoTrackButton } from '../../../components/button';
import { AutoTrackForm } from '../../../components/form/form';
import { useForm } from 'antd/lib/form/Form';
import { AutoComplete, Form, SelectProps } from 'antd';
import styled from 'styled-components';
import { getServicePaging } from '../../../api/setting/services';
import { getUsersPaging } from '../../../api/setting/users';
import {
    getCarInServiceById,
    updateCarInService,
} from '../../../api/car-in-service';
import { Direction, SearchQueryParams } from '../../../interface/paging';
import debouce from 'lodash/debounce';

const WrapperForm = styled.div`
    .ant-form-item .ant-form-item-label-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    width: '100%',
});
const getMoveItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    background: isDragging ? '#F1E8FD' : 'white',
    color: isDragging ? '#46485C' : 'inherit',
    borderRadius: isDragging ? '8px' : 'inherit',
    border: isDragging ? '1px solid #7357FF' : 'inherit',
    boxShadow: isDragging ? '0px 4px 16px rgba(79, 117, 140, 0.24)' : 'inherit',
    ...draggableStyle,
});

type ServiceListProps = {
    carInService: TCarInService;
    mobileMode: boolean;
};

export const ServiceList: React.FC<ServiceListProps> = ({ carInService }) => {
    const { showSuccess, showError } = useNotification();
    const dispatch = useDispatch();
    const [form] = useForm();
    // const [taskDetails, setTaskDetails] = React.useState<TCarInService>();
    const [reviewServices, setReviewServices] = React.useState<
        TServiceDetail[]
    >([]);
    const [servicesOptions, setServicesOptions] = React.useState<
        SelectProps<object>['options']
    >([]);
    const [usersOptions, setUsersOptions] = React.useState<
        SelectProps<object>['options']
    >([]);
    const [serviceList, setServiceList] = React.useState<
        TService[] | undefined
    >([]);
    const [userServices, setUserServices] = React.useState<
        TUserService[] | undefined
    >([]);
    const [showSaveOrder, setShowSaveOrder] = React.useState(false);

    const loadServices = React.useCallback(
        async (val: string | undefined) => {
            try {
                dispatch(setLoading(true));
                const queryParams: SearchQueryParams = {
                    page: 1,
                    pageSize: 10,
                    searchWord: val,
                    sortBy: Direction.ASC,
                    searchColum: 'Name',
                };
                const newPayload = removeUndefinedAttribute(queryParams);
                const response = await getServicePaging(newPayload);
                if (response.status === 200) {
                    const data = response.data?.data ?? [];
                    setServiceList(data);
                    const options = data.map(item => ({
                        value: item.name,
                    }));
                    setServicesOptions(options);
                }
            } catch (error) {
                // Handle error
            } finally {
                dispatch(setLoading(false));
            }
        },
        [dispatch]
    );

    const loadDetails = React.useCallback(async () => {
        try {
            if (!carInService.carInServiceId) {
                return;
            }
            dispatch(setLoading(true));
            const result = await getCarInServiceById(
                carInService.carInServiceId
            );
            if (result.status === 200) {
                // const data = result.data ?? [];
                // if (Array.isArray(data)) {
                //     setTaskDetails(data[0]);
                // }
            }
        } catch (error) {
            // Handle error
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, carInService.carInServiceId]);

    const handleSearchServices = (value: string) => {
        onSearchKeywordServices(value);
    };

    const onSearchKeywordServices = React.useMemo(() => {
        return debouce(loadServices, 600);
    }, [loadServices]);

    React.useEffect(() => {
        return () => {
            onSearchKeywordServices.cancel();
        };
    }, [onSearchKeywordServices]);

    const loadUserServices = React.useCallback(
        async (val: string | undefined) => {
            try {
                dispatch(setLoading(true));
                const queryParams: SearchQueryParams = {
                    page: 1,
                    pageSize: 10,
                    searchWord: val,
                    sortBy: Direction.ASC,
                    searchColum: 'Username',
                };
                const newPayload = removeUndefinedAttribute(queryParams);
                const result = await getUsersPaging(newPayload);
                if (result.status === 200) {
                    const data = result.data?.data ?? [];
                    setUserServices(data);
                }
            } catch (error) {
                // Handle error
            } finally {
                dispatch(setLoading(false));
            }
        },
        [dispatch]
    );

    const handleSearchUsers = (value: string) => {
        onSearchKeywordUsers(value);
    };

    const onSearchKeywordUsers = React.useMemo(() => {
        return debouce(loadUserServices, 600);
    }, [loadUserServices]);

    React.useEffect(() => {
        return () => {
            onSearchKeywordUsers.cancel();
        };
    }, [onSearchKeywordUsers]);

    React.useEffect(() => {
        loadDetails();
        loadServices(undefined);
        loadUserServices(undefined);
    }, []);

    React.useEffect(() => {
        if (userServices && userServices.length > 0) {
            const options = userServices.map(item => ({
                value: item.username,
            }));
            setUsersOptions(options);
        }
    }, [userServices]);

    React.useEffect(() => {
        if (carInService?.services) {
            setReviewServices(carInService.services);
        }
    }, [carInService]);

    const onDragEnd = (result: any) => {
        const { source, destination } = result;
        if (!result.destination) {
            return;
        }
        const newData = reorder(
            reviewServices,
            source.index,
            destination.index
        );
        setReviewServices(newData);
        setShowSaveOrder(true);
    };

    React.useEffect(() => {
        if (serviceList && serviceList.length > 0) {
            const options = serviceList
                .filter(
                    item =>
                        !reviewServices?.some(
                            d => d.service?.serviceId === item.serviceId
                        )
                )
                .map(item => ({
                    value: item.name,
                }));
            setServicesOptions(options);
        }
    }, [serviceList, reviewServices]);

    const onDeleteService = async (index: number) => {
        try {
            dispatch(setLoading(true));
            const newServices: TServiceRequest[] = reviewServices?.map(
                item => ({
                    serviceId: item.service?.serviceId,
                    userId: item.user?.userId,
                    serviceDetailId: item.serviceDetailId,
                    updateType: EUpdateType.NOT_MODIFY,
                })
            ) as TServiceRequest[];
            newServices[index].updateType = EUpdateType.DELETE;
            const payload: TCarInServiceRequest = {
                carId: carInService?.carId,
                carInServiceId: carInService?.carInServiceId,
                completedBy: carInService?.completedBy,
                services: newServices,
                priority: carInService?.priority,
                status: carInService?.status,
            };
            const result = await updateCarInService(
                carInService?.carInServiceId ?? 0,
                payload
            );
            if (result.status === 200) {
                showSuccess(
                    'Delete service success',
                    'Service has been deleted'
                );
                setReviewServices(prev => prev.filter((_, i) => i !== index));
            }
        } catch (error) {
            // Handle error
            showError('Error', 'Delete service failed');
        } finally {
            dispatch(setLoading(false));
        }
    };

    const reviewServiceList = React.useMemo(() => {
        if (
            !reviewServices ||
            (reviewServices && reviewServices?.length === 0)
        ) {
            return null;
        }
        return reviewServices
            .filter(d => d !== undefined)
            .map((item, index) => {
                return (
                    <Draggable
                        key={`${index}_${item.service?.serviceId}`}
                        draggableId={`id_${item.service?.serviceId}_${index}`}
                        index={index}
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getMoveItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                )}
                            >
                                <RowService
                                    serviceData={item}
                                    index={index}
                                    key={item.service?.serviceId}
                                    onDelete={onDeleteService}
                                    onChangeUserAssigned={onChangeUserAssigned}
                                />
                            </div>
                        )}
                    </Draggable>
                );
            });
    }, [reviewServices]);

    const onAddService = React.useCallback(async () => {
        try {
            dispatch(setLoading(true));
            const values = await form.getFieldsValue();
            const serviceId = values.service;
            if (!serviceId) {
                showError('Error', 'Service is required');
                return;
            }
            const service = serviceList?.find(item => item.name === serviceId);
            if (!service) {
                showError('Error', 'Service not found');
                return;
            }
            const assign = values.assign;
            const user = userServices?.find(item => item.username === assign);
            const newService: TServiceDetail = {
                service: service,
                user: user,
            };
            const newServices: TServiceRequest[] = reviewServices?.map(
                item => ({
                    serviceId: item.service?.serviceId,
                    userId: item.user?.userId,
                    serviceDetailId: item.serviceDetailId,
                    updateType: EUpdateType.NOT_MODIFY,
                })
            ) as TServiceRequest[];
            newServices.push({
                serviceId: service?.serviceId,
                userId: user?.userId,
                updateType: EUpdateType.ADD,
            });

            const payload: TCarInServiceRequest = {
                carId: carInService?.carId,
                carInServiceId: carInService?.carInServiceId,
                completedBy: carInService?.completedBy,
                services: newServices,
                priority: carInService?.priority,
                status: carInService?.status,
            };
            const result = await updateCarInService(
                carInService?.carInServiceId ?? 0,
                payload
            );
            if (result.status === 200) {
                showSuccess('Save service success', 'Service has been created');
                setReviewServices(prev => [...prev, newService]);
                form.resetFields();
            }
        } catch (error) {
            dispatch(setLoading(false));
            showError('Error', 'Add service failed');
        } finally {
            dispatch(setLoading(false));
        }
    }, [
        dispatch,
        form,
        showSuccess,
        showError,
        serviceList,
        userServices,
        carInService,
    ]);

    const onChangeUserAssigned = React.useCallback(
        async (value: TUserService, index: number) => {
            try {
                dispatch(setLoading(true));
                const newServices: TServiceRequest[] = reviewServices?.map(
                    item => ({
                        serviceId: item.service?.serviceId,
                        userId: item.user?.userId,
                        serviceDetailId: item.serviceDetailId,
                        updateType: EUpdateType.NOT_MODIFY,
                    })
                ) as TServiceRequest[];
                newServices[index].userId = value.userId;
                newServices[index].updateType = EUpdateType.UPDATE;
                const payload: TCarInServiceRequest = {
                    carId: carInService?.carId,
                    carInServiceId: carInService?.carInServiceId,
                    completedBy: carInService?.completedBy,
                    services: newServices,
                    priority: carInService?.priority,
                    status: carInService?.status,
                };
                const result = await updateCarInService(
                    carInService?.carInServiceId ?? 0,
                    payload
                );
                if (result.status === 200) {
                    showSuccess(
                        'Save service success',
                        'User has been assigned'
                    );
                    setReviewServices(prev => {
                        const newServices = [...prev];
                        newServices[index].user = value;
                        return newServices;
                    });
                }
            } catch (error) {
                // Handle error
            } finally {
                dispatch(setLoading(false));
            }
        },
        [dispatch, showSuccess, carInService, reviewServices]
    );

    const onSaveOrder = React.useCallback(async () => {
        try {
            dispatch(setLoading(true));
            const newServices: TServiceRequest[] = reviewServices?.map(
                item => ({
                    serviceId: item.service?.serviceId,
                    userId: item.user?.userId,
                    serviceDetailId: item.serviceDetailId,
                    updateType: EUpdateType.NOT_MODIFY,
                })
            ) as TServiceRequest[];
            const payload: TCarInServiceRequest = {
                carId: carInService?.carId,
                carInServiceId: carInService?.carInServiceId,
                completedBy: carInService?.completedBy,
                services: newServices,
                priority: carInService?.priority,
                status: carInService?.status,
            };
            const result = await updateCarInService(
                carInService?.carInServiceId ?? 0,
                payload
            );
            if (result.status === 200) {
                showSuccess(
                    'Save service success',
                    'Service order has been saved'
                );
                setShowSaveOrder(false);
            }
        } catch (error) {
            // Handle error
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showSuccess, carInService, reviewServices]);

    const childForm = (
        <div className="grid max-md:grid-cols-1 grid-cols-[1fr_1fr_auto] max-md:justify-end items-center gap-[10px] w-full">
            <Form.Item name="service" label="Service">
                <AutoComplete
                    options={servicesOptions}
                    className="w-full"
                    size="large"
                    showSearch
                    popupClassName="certain-category-search-dropdown"
                    placeholder="Service Name"
                    onSearch={handleSearchServices}
                />
            </Form.Item>
            <Form.Item name="assign" label="Assign">
                <AutoComplete
                    options={usersOptions}
                    className="w-full"
                    size="large"
                    showSearch
                    popupClassName="certain-category-search-dropdown"
                    placeholder="Assignee"
                    onSearch={handleSearchUsers}
                />
            </Form.Item>
            <Form.Item>
                <AutoTrackButton
                    btnSize="sm"
                    btnType="outline"
                    onClick={onAddService}
                    htmlType="submit"
                >
                    Add Service
                </AutoTrackButton>
            </Form.Item>
        </div>
    );

    return (
        <div className="flex flex-col w-full bg-white rounded-[8px] gap-[20px]">
            <div className="flex flex-row w-full bg-gray-100 rounded-[8px] p-[10px]">
                <WrapperForm className="flex w-full">
                    <AutoTrackForm
                        labelAlign="left"
                        labelCol={{ flex: '80px' }}
                        form={form}
                        labelWrap
                        wrapperCol={{ flex: 1 }}
                        layout="inline"
                        className="w-full"
                        name="createService"
                        style={{ width: '100%' }}
                        childNode={childForm}
                    />
                </WrapperForm>
            </div>
            <div className="grid grid-cols-1 gap-[12px] rounded-[12px] bg-white">
                <div className="grid max-md:grid-cols-[_1fr_1fr] grid-cols-[50px_1fr_250px] justify-start items-center max-md:pl-[40px] pl-[55px] font-bold text-[16px] leading-[19px]">
                    <span className="max-md:hidden">No.</span>
                    <span>Service</span>
                    <span>Assign</span>
                </div>
                {reviewServices && reviewServices?.length > 0 && (
                    <div>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(
                                            snapshot.isDraggingOver
                                        )}
                                    >
                                        {reviewServiceList}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                )}
                {reviewServices &&
                    reviewServices?.length > 0 &&
                    showSaveOrder && (
                        <div className="flex justify-end mt-5">
                            <span>
                                <AutoTrackButton
                                    type="primary"
                                    btnSize="sm"
                                    btnType="primary"
                                    onClick={onSaveOrder}
                                >
                                    Save
                                </AutoTrackButton>
                            </span>
                        </div>
                    )}
            </div>
        </div>
    );
};
