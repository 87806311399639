import classNames from 'classnames';
import styled from 'styled-components';

export const Wrapper = styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
`;

export const ScannerContainer = styled.div`
    position: relative;
    isolation: isolate;
    &:before {
        content: '';
        position: absolute;
        inset: -2px;
        background: transparent;
        border: 2px solid rgba(255, 255, 255, 0.5);
        border-radius: inherit;
    }
    &.scanner-vin {
        width: 400px;
        height: 120px;
        max-width: 400px;
        border-radius: 8px;
        @media (max-width: 768px) {
            width: 320px;
            height: 96px;
            .scanner-corner {
                position: absolute;
                width: 24px;
                height: 24px;
                border-color: #7357ff;
                border-style: solid;
            }
        }
    }
    &.scanner-plate {
        width: 320px;
        height: 160px;
        max-width: 90vw;
        border-radius: 4px;
        @media (max-width: 768px) {
            width: 280px;
            height: 140px;
            .scanner-corner {
                position: absolute;
                width: 24px;
                height: 24px;
                border-color: #7357ff;
                border-style: solid;
            }
        }
    }
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.5);

    background-color: transparent;
    mix-blend-mode: screen
    backdrop-filter: none;
    

    .scanner-corner {
        position: absolute;
        width: 20px;
        height: 20px;
        border-color: #7357ff;
        border-style: solid;
        border-width: 0;
    }

    .corner-top-left {
        top: 0;
        left: 0;
        border-left-width: 4px;
        border-top-width: 4px;
    }

    .corner-top-right {
        top: 0;
        right: 0;
        border-right-width: 4px;
        border-top-width: 4px;
    }

    .corner-bottom-left {
        bottom: 0;
        left: 0;
        border-left-width: 4px;
        border-bottom-width: 4px;
    }

    .corner-bottom-right {
        bottom: 0;
        right: 0;
        border-right-width: 4px;
        border-bottom-width: 4px;
    }

    .scanning-line {
        position: absolute;
        left: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(
            90deg,
            transparent 0%,
            rgba(115, 87, 255, 0) 15%,
            rgba(115, 87, 255, 0.8) 50%,
            rgba(115, 87, 255, 0) 85%,
            transparent 100%
        );
        filter: blur(0.5px);
        animation: scanning 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
        will-change: top;
        z-index: 2;
    }

    @keyframes scanning {
        0% {
            top: 0;
        }
        100% {
            top: 100%;
        }
    }
`;

export enum ScannerType {
    VIN = 'VIN',
    PLATE = 'PLATE',
}

type ScannerOverlayProps = {
    className?: string;
    scannerType: ScannerType;
};

export const ScannerOverlay: React.FC<ScannerOverlayProps> = ({
    scannerType,
}) => {
    return (
        <Wrapper>
            <ScannerContainer
                className={classNames([
                    {
                        'scanner-vin': scannerType === ScannerType.VIN,
                        'scanner-plate': scannerType === ScannerType.PLATE,
                    },
                ])}
            >
                <div className="scanner-corner corner-top-left" />
                <div className="scanner-corner corner-top-right" />
                <div className="scanner-corner corner-bottom-left" />
                <div className="scanner-corner corner-bottom-right" />
                <div className="scanning-line" />
            </ScannerContainer>
            <div>
                {scannerType === ScannerType.VIN ? (
                    <span className="text-white text-body-medium text-center">
                        Align the VIN barcode within the frame
                    </span>
                ) : (
                    <span className="text-white text-body-medium text-center">
                        Align the license plate within the frame
                    </span>
                )}
            </div>
        </Wrapper>
    );
};
