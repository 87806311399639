import Tesseract from 'tesseract.js';

export const preprocessImage = async (imageFile: File): Promise<File> => {
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    return new Promise((resolve, reject) => {
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx?.drawImage(img, 0, 0);
            const imageData = ctx?.getImageData(
                0,
                0,
                canvas.width,
                canvas.height
            );
            const data = imageData?.data;
            for (let i = 0; i < data!.length; i += 4) {
                const avg = (data![i] + data![i + 1] + data![i + 2]) / 3;
                data![i] = avg;
                data![i + 1] = avg;
                data![i + 2] = avg;
            }
            ctx?.putImageData(imageData!, 0, 0);

            canvas.toBlob(blob => {
                if (blob) {
                    resolve(
                        new File([blob], imageFile.name, { type: 'image/png' })
                    );
                } else {
                    reject(new Error('Image preprocessing failed'));
                }
            }, 'image/png');
        };
        img.onerror = reject;
        img.src = URL.createObjectURL(imageFile);
    });
};

export const scaleImage = async (
    imageFile: File,
    width: number,
    height: number
): Promise<File> => {
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    return new Promise((resolve, reject) => {
        img.onload = () => {
            canvas.width = width;
            canvas.height = height;
            ctx?.drawImage(img, 0, 0, width, height);

            canvas.toBlob(blob => {
                if (blob) {
                    resolve(
                        new File([blob], imageFile.name, { type: 'image/png' })
                    );
                } else {
                    reject(new Error('Image scaling failed'));
                }
            }, 'image/png');
        };
        img.onerror = reject;
        img.src = URL.createObjectURL(imageFile);
    });
};

export const getCroppedImg = async (
    imageSrc: string,
    targetWidth: number,
    targetHeight: number
): Promise<string> => {
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    return new Promise((resolve, reject) => {
        img.onload = () => {
            const imgWidth = img.width;
            const imgHeight = img.height;
            const centerX = imgWidth / 2;
            const centerY = imgHeight / 2;
            const x = centerX - targetWidth / 2;
            const y = centerY - targetHeight / 2;
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            ctx?.clearRect(0, 0, targetWidth, targetHeight);
            ctx?.drawImage(
                img,
                x,
                y,
                targetWidth,
                targetHeight,
                0,
                0,
                targetWidth,
                targetHeight
            );
            const croppedImageSrc = canvas.toDataURL('image/jpeg');
            resolve(croppedImageSrc);
        };
        img.onerror = reject;
        img.src = imageSrc;
    });
};

export const readImageOCR = async (imageFile: File): Promise<any> => {
    try {
        const preprocessedImage = await preprocessImage(imageFile);
        const result = await Tesseract.recognize(preprocessedImage, 'eng', {
            // logger: m => {
            //     /* eslint-disable */
            //     console.log(m);
            // },
            logger: () => {},
        });
        return result;
    } catch (error) {
        throw new Error('Failed to read text from image');
    }
};
