import { Table, Row, Modal, Input, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { SortOrder } from 'antd/es/table/interface';
import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useSortData } from '.';
import { AutoTrackButton, IconButton } from '../../components/button';
import { ICSort, ICAdd, ICNoneData } from '../../icons';
import { ICMaterialSymbol } from '../../icons/material-symbol';
import { UserServiceForm } from './create';
import { TUserService } from '../start-new-task/type';
import { AutoTrackPagination } from '../../components/pagination';
import { useNotification } from '../../hooks/useNotification';
import dayjs from 'dayjs';
import { setLoading } from '../../redux/slices/appInfo';
import {
    createUser,
    deleteUser,
    updateRole,
    updateUser,
} from '../../api/setting/users';
import {
    Direction,
    IPagination,
    SearchQueryParams,
} from '../../interface/paging';
import { removeUndefinedAttribute } from '../../utils';
import debouce from 'lodash/debounce';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase/firebaseConfig';
import { ROLE_MAPPING } from '../../utils/constants';
import { useScreenSize } from '../../hooks/useScreenSize';
import { MobileListUserServicesPage } from './mobile-list';

const ActionMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    cursor: pointer;
    padding-right: 16px;
    &:hover {
        background-color: #f7f8fa;
    }
    button {
        padding: 0 !important;
    }
`;

const WrapperTable = styled.div`
    .ant-table-thead > tr > th {
        background-color: white;
    }
    .data-row:nth-child(odd) {
        background-color: #f7f8fa;
    }
    .ant-table-column-sorters .ant-table-column-sorter-inner {
        .anticon {
            display: none;
        }
    }
`;

const WrapperInput = styled.div`
    @media (max-width: 768px) {
    .ant-input-group-wrapper {
        width: 100% !important;
    }
`;

const WrapperButton = styled.div`
    @media (max-width: 768px) {
        width: 100%;
        .ant-btn {
            width: 100% !important;
        }
    }
`;

const TitleRenderer: React.FC<{ title: string }> = ({ title }) => {
    return <div className="text-body text-high-em">{title}</div>;
};
type SortableColumnProps = {
    title: string;
    order?: SortOrder;
    name: string;
};
const SortableColumn: React.FC<SortableColumnProps> = ({
    title,
    order,
    name,
}) => {
    const { setSortData } = useSortData();
    return (
        <div
            className="flex space-x-3 items-center justify-between select-none group"
            onClick={() => {
                setSortData({
                    field: order === 'descend' ? 'createdDate' : name,
                    order:
                        order === 'descend' ? 'desc' : !order ? 'asc' : 'desc',
                });
            }}
        >
            <span className="text-body-bold text-high-em">
                {title as string}
            </span>
            <ICSort order={order} />
        </div>
    );
};

type UserServicePageListProps = {
    userServiceList: TUserService[];
    setUserServiceList: (userServiceList: TUserService[]) => void;
    keyword?: string;
    pagination?: IPagination;
    onChangePaging: ({ page }: { page: number }) => void;
    onReload: () => void;
    filterParams: SearchQueryParams;
    onFilterData: (payload: SearchQueryParams) => void;
};
const KeywordContext = React.createContext<string>('');

export const UserServicePageList: React.FC<UserServicePageListProps> = ({
    userServiceList,
    keyword,
    pagination,
    onChangePaging,
    onReload,
    filterParams,
    onFilterData,
}) => {
    const size = useScreenSize();
    const mobileMode = size === 'xs' || size === 'sm';
    const dispatch = useDispatch();
    const { showSuccess, showError } = useNotification();
    const onChangePage = (page: number) => {
        onChangePaging({ page });
    };
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [userServiceId, setUserServiceId] = React.useState<
        string | undefined
    >('');
    const [showUserServiceDialog, setShowUserServiceDialog] =
        React.useState(false);
    const [userServiceSelected, setUserServiceSelected] = React.useState<
        TUserService | undefined
    >(undefined);
    const [form] = useForm();

    const [phoneNumbers, setPhoneNumbers] = React.useState<string[]>([]);

    // handle search keyword
    const [searchColumn, setSearchColumn] = React.useState<string>('Username');
    const [searchWord, setSearchWord] = React.useState<string | undefined>('');
    const selectBeforeOptions = ['Username', 'Email', 'PhoneNumber'];
    const onSearchData = React.useCallback(
        async (searchWord: string | undefined, searchColumnTemp?: string) => {
            try {
                const queryParams: SearchQueryParams = {
                    page: 1,
                    pageSize: filterParams?.pageSize || 10,
                    searchWord: searchWord,
                    sortBy: Direction.ASC,
                    searchColum: searchColumnTemp || searchColumn,
                };
                const newPayload = removeUndefinedAttribute(queryParams);
                onFilterData(newPayload);
            } catch (error) {
                // Handle error
            }
        },
        [filterParams, searchColumn]
    );

    const handleSearchData = (value: string | undefined) => {
        onSearchKeyword(value);
        setSearchWord(value);
    };

    const onSearchKeyword = React.useMemo(() => {
        return debouce(onSearchData, 600);
    }, [onSearchData]);

    React.useEffect(() => {
        return () => {
            onSearchKeyword.cancel();
        };
    }, [onSearchKeyword]);
    // end handle search keyword

    const onShowDeleteModal = React.useCallback(
        (id: string) => {
            setUserServiceId(id);
            setShowDeleteModal(true);
        },
        [setShowDeleteModal]
    );

    const columns: ColumnsType<TUserService> = [
        {
            title: 'Name',
            dataIndex: 'username',
            field: 'username',
            key: 'username',
            sorter: true,
            render: (title: string) => {
                return <TitleRenderer title={title} />;
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            field: 'email',
            key: 'email',
            sorter: true,
            render: (title: string) => {
                return <TitleRenderer title={title} />;
            },
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            field: 'phoneNumber',
            key: 'phoneNumber',
            sorter: true,
            render: (values: string[]) => {
                return values?.join(', ');
            },
        },
        {
            title: 'Roles',
            dataIndex: 'roles',
            field: 'roles',
            key: 'roles',
            sorter: false,
            render: (roles: string[]) => {
                if (roles) {
                    const roleNames = roles.map(role => ROLE_MAPPING[role]);
                    return roleNames.join(', ');
                }
                return '';
            },
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            field: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: (val: string) => {
                const newDate = dayjs(val, 'YYYY-MM-DD').format('YYYY-MM-DD');
                return newDate;
            },
        },
        {
            title: 'Action',
            dataIndex: 'userId',
            key: 'userId',
            width: '40px',
            render: (userId: string, data: TUserService) => {
                return (
                    <ActionMenu>
                        <IconButton
                            icon={<ICMaterialSymbol icon="edit_square" />}
                            btnType="tertiary"
                            onClick={() => {
                                setUserServiceSelected(data);
                                const phoneNumbersTemp = data.phoneNumber || [];
                                setPhoneNumbers(phoneNumbersTemp);
                                setShowUserServiceDialog(true);
                            }}
                        />
                        <IconButton
                            icon={<ICMaterialSymbol icon="delete" />}
                            onClick={() => onShowDeleteModal(userId)}
                            btnType="tertiary"
                        />
                    </ActionMenu>
                );
            },
        },
    ].map((col: ColumnType<TUserService>) => {
        if (col.sorter) {
            const oldTitle = col.title;
            col.title = ({ sortColumns }) => {
                const sortedColumn = sortColumns?.find(
                    ({ column }) => column.key === col.key
                );
                return (
                    <SortableColumn
                        title={oldTitle as string}
                        order={sortedColumn?.order}
                        name={col.dataIndex as string}
                    />
                );
            };
        } else {
            col.title = (
                <div className="text-body-bold text-high-em">
                    {col.title as string}
                </div>
            );
        }
        col.showSorterTooltip = false;
        return col;
    });

    const saveRoles = async (
        userId: string,
        roles: string[],
        message: string
    ) => {
        try {
            const response = await updateRole(userId, roles);
            if (response.status === 200) {
                showSuccess('Save User success', message);
            }
        } catch (error) {
            showError('Save Roles failed', 'Please check your input data');
        }
    };

    const doSaveUser = React.useCallback(
        async (userId: string | undefined, payload: TUserService) => {
            try {
                dispatch(setLoading(true));
                if (userId) {
                    const result = await updateUser(userId, payload);
                    if (result.status === 204) {
                        await saveRoles(
                            userId,
                            payload.roles || [],
                            'User has been updated'
                        );
                    }
                } else {
                    const result = await createUser(payload);
                    if (result.status === 201) {
                        // add user to firebase
                        await createUserWithEmailAndPassword(
                            auth,
                            payload.email?.toLowerCase() || '',
                            payload.passwordHash || ''
                        );
                        showSuccess(
                            'Save User success',
                            'User has been created'
                        );
                    }
                }
                onReload();
                setShowUserServiceDialog(false);
            } catch (error: any) {
                const errorMessage =
                    error?.response?.data?.detail ||
                    'Please check your input data';
                showError('Save User failed', errorMessage);
            } finally {
                dispatch(setLoading(false));
            }
        },
        [userServiceList, dispatch, showSuccess, showError]
    );

    const onSaveUsers = React.useCallback(() => {
        form.validateFields()
            .then(values => {
                const userData = {
                    ...userServiceSelected,
                    ...values,
                };
                const newPhoneNumbers =
                    (phoneNumbers.filter(
                        phoneNumber => phoneNumber !== ''
                    ) as string[]) || [];
                userData.phoneNumber = newPhoneNumbers;
                if (userServiceSelected) {
                    doSaveUser(userData.userId, userData);
                } else {
                    doSaveUser(undefined, userData);
                }
            })
            .catch(() => {
                showError('Save User failed', 'Please check your input data');
            });
    }, [form, phoneNumbers, userServiceList, userServiceSelected, showError]);

    const onDeleteUserService = React.useCallback(async () => {
        if (!userServiceId) {
            return;
        }
        try {
            dispatch(setLoading(true));
            const result = await deleteUser(userServiceId);
            setShowDeleteModal(false);
            if (result.status === 200) {
                showSuccess('Delete User success', 'User has been deleted');
                onReload();
            }
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.detail ||
                'Error occurred while deleting UserService';
            showError('Delete User Service failed', errorMessage);
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showSuccess, userServiceId, showError]);

    return (
        <WrapperTable className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border">
            <div className="flex w-full px-[12px] py-[20px] shadow-e-03 max-md:justify-center items-center max-md:flex-wrap gap-3 justify-between bg-white rounded-[12px]">
                <div className="flex gap-3 items-center justify-center w-full flex-wrap">
                    <WrapperInput className="flex items-center w-full gap-2">
                        <Input
                            placeholder="Search by ..."
                            value={searchWord}
                            onChange={e => handleSearchData(e.target.value)}
                            style={{
                                width: '420px',
                            }}
                            addonBefore={
                                <>
                                    <Select
                                        className="select-before"
                                        defaultValue="Make"
                                        value={searchColumn}
                                        style={{ width: 122 }}
                                        onChange={(value: string) => {
                                            setSearchColumn(value);
                                            onSearchData(searchWord, value);
                                        }}
                                    >
                                        {selectBeforeOptions.map(
                                            (item, index) => (
                                                <Select.Option
                                                    key={index}
                                                    value={item}
                                                >
                                                    {item}
                                                </Select.Option>
                                            )
                                        )}
                                    </Select>
                                </>
                            }
                            size="large"
                        />
                    </WrapperInput>
                </div>
                <WrapperButton className="flex gap-[10px]">
                    <AutoTrackButton
                        btnType="primary"
                        onClick={() => {
                            setUserServiceSelected(undefined);
                            setPhoneNumbers([]);
                            setShowUserServiceDialog(true);
                        }}
                        style={{
                            width: '220px',
                        }}
                        btnSize="md"
                    >
                        <div className="flex space-x-2 items-center">
                            <ICAdd stroke="#fff" width={18} height={18} />
                            <span>Create new User</span>
                        </div>
                    </AutoTrackButton>
                </WrapperButton>
            </div>
            <div
                className={classNames([
                    'flex flex-col w-full h-full rounded-[12px] overflow-hidden shadow-e-03',
                    !mobileMode && 'bg-white',
                ])}
            >
                <KeywordContext.Provider value={keyword ?? ''}>
                    {mobileMode ? (
                        <MobileListUserServicesPage
                            userServiceList={userServiceList}
                            onEditUserService={(item: TUserService) => {
                                setUserServiceSelected(item);
                                setShowUserServiceDialog(true);
                            }}
                            onDeleteUserService={(item: TUserService) => {
                                const userId = item.userId ?? '';
                                onShowDeleteModal(userId.toString());
                            }}
                        />
                    ) : (
                        <Table
                            key="userId"
                            className={classNames('white-header w-full')}
                            columns={columns}
                            dataSource={userServiceList}
                            rowClassName="cursor-pointer data-row"
                            rowKey="userId"
                            scroll={{ x: 'max-content' }}
                            locale={{
                                emptyText: (
                                    <EmptyTable
                                        keyword={keyword}
                                        onCreateNew={() => {
                                            setUserServiceSelected(undefined);
                                            setShowUserServiceDialog(true);
                                        }}
                                    />
                                ),
                            }}
                            pagination={false}
                        />
                    )}
                </KeywordContext.Provider>
                {pagination?.totalPage && pagination.totalPage > 1 ? (
                    <Row
                        gutter={16}
                        style={{
                            margin: 0,
                        }}
                        className={classNames([
                            'grid items-center max-md:justify-center justify-end w-full bg-white p-[16px] border-t border-solid border-outline-med rounded-b-[12px]',
                            'max-md:fixed max-md:bottom-0 max-md:left-0 max-md:right-0 max-md:z-10 max-md:rounded-none',
                        ])}
                    >
                        <AutoTrackPagination
                            onChange={onChangePage}
                            defaultCurrent={pagination?.page}
                            defaultPageSize={pagination?.pageSize}
                            total={pagination?.totalCount}
                            currentPage={pagination?.page}
                            showSizeChanger={false}
                        />
                    </Row>
                ) : null}
            </div>

            <Modal
                centered
                onCancel={() => setShowDeleteModal(false)}
                open={showDeleteModal}
                title={<div className="font-bold text-[18px]">Confirm</div>}
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                        <AutoTrackButton
                            btnType="sub"
                            btnSize="sm"
                            onClick={() => setShowDeleteModal(false)}
                        >
                            Cancel
                        </AutoTrackButton>
                        <AutoTrackButton
                            btnType="danger"
                            btnSize="sm"
                            onClick={onDeleteUserService}
                        >
                            Delete
                        </AutoTrackButton>
                    </div>
                }
            >
                <p>Are you sure you want to delete this user?</p>
            </Modal>

            <Modal
                centered
                onCancel={() => setShowUserServiceDialog(false)}
                open={showUserServiceDialog}
                maskClosable={false}
                destroyOnClose
                afterClose={() => form.resetFields()}
                title={
                    <div className="font-bold text-[18px]">
                        {userServiceSelected
                            ? 'Edit User'
                            : 'New User Register'}
                    </div>
                }
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                        <AutoTrackButton
                            btnType="sub"
                            btnSize="sm"
                            onClick={() => setShowUserServiceDialog(false)}
                        >
                            Cancel
                        </AutoTrackButton>
                        <AutoTrackButton
                            btnType="primary"
                            btnSize="sm"
                            onClick={onSaveUsers}
                        >
                            Save
                        </AutoTrackButton>
                    </div>
                }
            >
                <div className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border">
                    <UserServiceForm
                        userServiceData={userServiceSelected}
                        form={form}
                        phoneNumbers={phoneNumbers}
                        setPhoneNumbers={setPhoneNumbers}
                    />
                </div>
            </Modal>
        </WrapperTable>
    );
};

type IEmptyTableProps = {
    keyword: string | undefined;
    onCreateNew: () => void;
};

const EmptyTable: React.FC<IEmptyTableProps> = ({ keyword, onCreateNew }) => {
    return (
        <div className="my-6 flex flex-col gap-[5px]">
            <div className="flex justify-center">
                <ICNoneData />
            </div>
            <div className="text-standard-bold text-high-em">
                {keyword
                    ? `No data found with keyword “${keyword}”`
                    : 'No data found'}
            </div>
            <div className="flex justify-center">
                <span>
                    <AutoTrackButton
                        btnType="outline"
                        btnSize="sm"
                        style={{
                            borderRadius: '144px',
                        }}
                        onClick={() => onCreateNew()}
                    >
                        <div className="flex space-x-2 items-center">
                            <ICAdd stroke="#7357FF" width={18} height={18} />
                            <span>Create new User</span>
                        </div>
                    </AutoTrackButton>
                </span>
            </div>
        </div>
    );
};
