import { isSupported } from 'firebase/messaging';

export const isBrowserCompatible = async (): Promise<boolean> => {
    const hasFirebaseMessagingSupport = await isSupported();
    const isNotificationSupported = 'Notification' in window;

    if (!isNotificationSupported) {
        /* eslint-disable */
        console.warn('Notifications are not supported in this browser.');
    }
    return hasFirebaseMessagingSupport && isNotificationSupported;
};

export const requestNotificationPermission =
    async (): Promise<NotificationPermission> => {
        try {
            const permission = await Notification.requestPermission();
            return permission;
        } catch (error) {
            /* eslint-disable */
            console.error('Failed to request notification permission', error);
            return 'denied';
        }
    };
