import { Button } from 'antd';
import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import styled from 'styled-components';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';

const Wrapper = styled.div`
    input {
        font-weight: 500;
        color: #110c22;
        border: 1px solid #ececed;
        padding: 10px;
        width: 100%;
        border-radius: 8px;
        &:focus {
            border-color: #007faa;
            box-shadow:
                0px 0px 0px 4px #e6f0fe,
                0px 2px 4px 0px rgba(17, 12, 34, 0.12);
            outline: 0;
            background-color: var(--ant-input-active-bg);
        }
    }
`;

type PhoneControlProps = {
    phoneNumbers: string[];
    setPhoneNumbers: React.Dispatch<React.SetStateAction<string[]>>;
};

export const PhoneControl: React.FC<PhoneControlProps> = ({
    phoneNumbers,
    setPhoneNumbers,
}) => {
    const [country, setCountry] = useState<any | undefined>('US');
    const handlePhoneChange = (index: number, value: string | undefined) => {
        const newPhoneNumbers = [...phoneNumbers];
        newPhoneNumbers[index] = value || '';
        setPhoneNumbers(newPhoneNumbers);
    };

    const addPhoneNumber = () => {
        setPhoneNumbers([...phoneNumbers, '']);
    };

    const removePhoneNumber = (index: number) => {
        const newPhoneNumbers = phoneNumbers.filter((_, i) => i !== index);
        setPhoneNumbers(newPhoneNumbers);
    };

    const handleCountryChange = (newCountry: any) => {
        setCountry(newCountry);
    };

    return (
        <Wrapper className="pb-3">
            {phoneNumbers.map((phoneNumber, index) => (
                <div key={index} className="grid grid-cols-[1fr_25px] gap-2">
                    <div className="grid grid-cols-[130px_1fr] items-center gap-1 relative mb-2">
                        <label className="mb-[5px]">
                            Phone Number {index + 1}
                        </label>
                        <PhoneInput
                            defaultCountry={country}
                            value={phoneNumber}
                            className="form-control"
                            onChange={val => handlePhoneChange(index, val)}
                            onCountryChange={val => handleCountryChange(val)}
                        />
                    </div>
                    <MinusCircleOutlined
                        onClick={() => {
                            removePhoneNumber(index);
                        }}
                    />
                </div>
            ))}
            <div className="grid grid-cols-[130px_1fr]">
                <div>&nbsp;</div>
                <Button
                    type="dashed"
                    size="large"
                    onClick={() => {
                        addPhoneNumber();
                    }}
                    block
                    icon={<PlusOutlined />}
                >
                    Add Phone
                </Button>
            </div>
        </Wrapper>
    );
};
