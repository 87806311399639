import { ApiInstance } from '..';
import { TCarInServiceRequest } from '../../pages/start-new-task/type';
import { convert2QueryString } from '../../utils/convert2QueryString';
import { TSearchCarInService, TServiceRecordRequest } from './type';

export const saveCarInService = (payload: TCarInServiceRequest) => {
    return ApiInstance.getInstance().post('/api/carsinservices/add', payload);
};
export const searchCarInService = (payload: TSearchCarInService) => {
    if (payload?.priority === 'All') {
        delete payload.priority;
    }
    const requestURL =
        '/api/carsinservices/search?' + convert2QueryString(payload);
    return ApiInstance.getInstance().get(requestURL);
};

export const getCarInServiceById = (id: number | string) => {
    return ApiInstance.getInstance().get(`/api/carsinservices/${id}`);
};

export const getCarInServiceByUser = (
    userId: number | undefined,
    payload: TSearchCarInService
) => {
    const requestURL =
        `/api/carsinservices/user/${userId}?` + convert2QueryString(payload);
    return ApiInstance.getInstance().get(requestURL);
};

export const updateCarInService = (
    id: number | string,
    payload: TCarInServiceRequest
) => {
    const completedBy = new Date(payload?.completedBy ?? '').toISOString();
    const newRequest = {
        ...payload,
        completedBy,
    };
    return ApiInstance.getInstance().put(
        `/api/carsinservices/update/${id}`,
        newRequest
    );
};

export const addServiceRecord = (payload: TServiceRecordRequest) => {
    return ApiInstance.getInstance().post('/api/servicerecord/add', payload);
};
