import React from 'react';
import { useForm } from 'antd/lib/form/Form';
import { Form } from 'antd';
import { AutoTrackButton } from '../../../components/button';
import { AutoTrackForm } from '../../../components/form/form';
import {
    AutoTrackInput,
    AutoTrackInputPassword,
} from '../../../components/form/input';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/slices/appInfo';
import { useNavigate } from 'react-router-dom';
import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import { changePassword, TChangePassword } from '../../../api/setting/users';
import { useNotification } from '../../../hooks/useNotification';
import { removeUserInfo } from '../../../redux/actions';
import { validatePasswordStrength } from '../../../utils/passwordValidator';

type FieldType = {
    email: string;
    password: string;
    confirmPassword: string;
};

type ChangePasswordFormProps = {
    email: string | undefined;
    setOpenChangePasswordDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
    email,
    setOpenChangePasswordDialog,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showError, showSuccess } = useNotification();
    const [changePasswordForm] = useForm<FieldType>();
    const validatePassword = (_: RuleObject, value: StoreValue) => {
        if (!value || changePasswordForm.getFieldValue('password') === value) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('The two passwords do not match!'));
    };

    const onFinished = React.useCallback(
        async (values: FieldType) => {
            try {
                dispatch(setLoading(true));
                const { email, password } = values || {};
                const payload: TChangePassword = {
                    email,
                    newPassword: password,
                };
                await changePassword(payload);
                setOpenChangePasswordDialog(false);
                showSuccess(
                    'Change Password success',
                    'Password has been changed'
                );
                dispatch(removeUserInfo());
                // force logout
                navigate('/login');
            } catch (error) {
                showError(
                    'Change Password failed',
                    'Please check your email and password'
                );
            } finally {
                dispatch(setLoading(false));
            }
        },
        [dispatch, showSuccess, showError, navigate]
    );

    React.useEffect(() => {
        if (email) {
            changePasswordForm.setFieldsValue({
                email,
            });
        }
    }, [email]);

    return (
        <div className="flex items-center justify-center pt-[16px]">
            <div className="max-md:max-w-[478px] max-md:w-auto w-[478px] flex flex-col gap-[24px] rounded-[12px] overflow-hidden box-border bg-white">
                <AutoTrackForm
                    form={changePasswordForm}
                    name="changePasswordForm"
                    layout="vertical"
                    onFinish={onFinished}
                    childNode={
                        <>
                            <div className="form flex flex-col w-full gap-[16px]">
                                <div>
                                    <Form.Item<FieldType>
                                        label="Email"
                                        name="email"
                                        required
                                    >
                                        <AutoTrackInput
                                            size="large"
                                            disabled
                                            maxLength={50}
                                            placeholder="Email"
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item<FieldType>
                                        label="New Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please input your new password!',
                                            },
                                            {
                                                validator: (_, value) => {
                                                    const { isValid } =
                                                        validatePasswordStrength(
                                                            value
                                                        );
                                                    if (isValid) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        new Error(
                                                            'Password does not strong enough'
                                                        )
                                                    );
                                                },
                                            },
                                        ]}
                                    >
                                        <AutoTrackInputPassword
                                            size="large"
                                            maxLength={20}
                                            placeholder="Password"
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item<FieldType>
                                        label="Confirm New Password"
                                        name="confirmPassword"
                                        dependencies={['password']}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please confirm your password!',
                                            },
                                            {
                                                validator: (_, value) => {
                                                    const { isValid } =
                                                        validatePasswordStrength(
                                                            value
                                                        );
                                                    if (isValid) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        new Error(
                                                            'Password does not strong enough'
                                                        )
                                                    );
                                                },
                                            },
                                            { validator: validatePassword },
                                        ]}
                                    >
                                        <AutoTrackInputPassword
                                            size="large"
                                            maxLength={20}
                                            placeholder="Confirm New Password"
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="flex flex-row items-end justify-end w-full gap-[10px] pt-[16px]">
                                <div className="flex gap-[5px] items-center justify-center text-center text-high-em text-body">
                                    <AutoTrackButton
                                        btnSize="sm"
                                        btnType="sub"
                                        onClick={() =>
                                            setOpenChangePasswordDialog(false)
                                        }
                                    >
                                        Cancel
                                    </AutoTrackButton>
                                </div>
                                <div>
                                    <AutoTrackButton
                                        btnSize="sm"
                                        htmlType="submit"
                                    >
                                        Save
                                    </AutoTrackButton>
                                </div>
                            </div>
                        </>
                    }
                />
            </div>
        </div>
    );
};
