import { AutoComplete, Button, Form, SelectProps, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { TRepairService, TService, TUserService } from '../../type';
import debouce from 'lodash/debounce';
import { Direction, SearchQueryParams } from '../../../../interface/paging';
import { removeUndefinedAttribute } from '../../../../utils';
import { getServicePaging } from '../../../../api/setting/services';
import { getUsersPaging } from '../../../../api/setting/users';

const Wrapper = styled.div`
    .ant-row {
        align-items: center;
        width: 100%;
        .ant-col {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            .ant-form-item-control-input {
                width: 100%;
            }
        }
    }
    .ant-form-item-has-error {
        .ant-row {
            align-items: flex-start;
        }
    }
`;

const FormWrapper = styled.div`
    .ant-form-item {
        width: 100%;
        max-width: 600px;
    }
    .ant-form-item .ant-form-item-control-input-content,
    ant-form-item-control-input-content {
        display: flex;
        flex-direction: row;
    }
    .ant-form-item .ant-form-item-control-input {
        align-items: flex-start;
        flex-direction: row;
    }
    .ant-form-horizontal .ant-form-item-control {
        margin-left: 0;
    }
    .ant-space-item {
        width: 100%;
    }
    .ant-space-item:first-child {
        display: flex;
        justify-content: center;
        width: 50px;
        padding-top: 10px;
    }
    .ant-space-item:last-child {
        display: flex;
        justify-content: center;
        width: 50px;
        padding-top: 14px;
    }
`;

const FieldWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    align-items: flex-start;
    gap: 10px;
`;

type AddServicesProps = {
    isUserRole: boolean;
    username: string;
    serviceList: TService[] | undefined;
    setServiceList: React.Dispatch<
        React.SetStateAction<TService[] | undefined>
    >;
    servicesSelected: TService[] | undefined;
    setServicesSelected: React.Dispatch<
        React.SetStateAction<TService[] | undefined>
    >;
    userServices: TUserService[] | undefined;
    setUserServices: React.Dispatch<TUserService[] | undefined>;
    repairServices: TRepairService[] | undefined;
    setRepairServices: React.Dispatch<TRepairService[] | undefined>;
};
export const AddServices: React.FC<AddServicesProps> = ({
    isUserRole,
    username,
    serviceList,
    setServiceList,
    servicesSelected,
    setServicesSelected,
    userServices,
    setUserServices,
    repairServices,
    setRepairServices,
}) => {
    const [userSelected, setUserSelected] = React.useState<
        string | undefined
    >();
    const [servicesOptions, setServicesOptions] = React.useState<
        SelectProps<object>['options']
    >([]);
    const [usersOptions, setUsersOptions] = React.useState<
        SelectProps<object>['options']
    >([]);

    const onSelectedService = React.useCallback(
        (value: string, index: number) => {
            const service = serviceList?.find(
                service => service.name === value
            );
            if (service) {
                const newServices = servicesSelected
                    ? [...servicesSelected]
                    : [];
                newServices[index] = service;
                setServicesSelected(newServices);
                if (repairServices?.length === 0) {
                    setRepairServices([
                        {
                            ...service,
                            users: [],
                        },
                    ]);
                } else {
                    const currentUser = userServices?.find(
                        user => user.username === userSelected
                    );
                    setRepairServices(
                        repairServices?.find((repairService, i) => i === index)
                            ? repairServices?.map((repairService, i) => {
                                  if (i === index) {
                                      return {
                                          ...service,
                                          users: repairService.users,
                                      };
                                  }
                                  return repairService;
                              })
                            : [
                                  ...(repairServices ?? []),
                                  {
                                      ...service,
                                      users: currentUser ? [currentUser] : [],
                                  },
                              ]
                    );
                }
            }
        },
        [
            serviceList,
            userSelected,
            usersOptions,
            setRepairServices,
            repairServices,
            servicesSelected,
        ]
    );

    const onSelectUser = React.useCallback(
        (value: string, index: number) => {
            const user = userServices?.find(user => user.username === value);
            if (user) {
                if (repairServices && repairServices[index]) {
                    repairServices[index].users = [user];
                    setRepairServices([...repairServices]);
                } else {
                    const newServices: TService = {
                        serviceId: 0,
                        name: '',
                        price: 0,
                    };
                    setRepairServices([
                        {
                            ...newServices,
                            users: [user],
                        },
                    ]);
                }
            }
        },
        [userServices, repairServices]
    );

    const onRemoveService = React.useCallback(
        (value: number) => {
            const newServices =
                servicesSelected &&
                servicesSelected.filter((item, index) => index !== value);
            if (newServices) {
                setServicesSelected(newServices);
                const newRepairServices = repairServices?.filter(
                    (item, index) => index !== value
                );
                setRepairServices(newRepairServices);
            }
        },
        [serviceList, servicesSelected]
    );

    const onClearCar = React.useCallback(
        (index: number) => {
            if (repairServices && repairServices[index]) {
                repairServices[index].users = [];
                setRepairServices([...repairServices]);
            }
        },
        [repairServices]
    );

    React.useEffect(() => {
        setServicesOptions(() => {
            if (serviceList?.length === 0) {
                return [];
            }
            if (servicesSelected && servicesSelected.length > 0) {
                return serviceList
                    ?.filter(
                        service =>
                            !servicesSelected.some(
                                selectedService =>
                                    selectedService.name === service.name
                            )
                    )
                    .map(service => ({
                        value: service.name,
                    }));
            }
            return serviceList?.map(service => ({
                value: service.name,
            }));
        });
    }, [serviceList, servicesSelected]);

    React.useEffect(() => {
        if (userServices?.length === 0) {
            return;
        }
        if (isUserRole && userServices?.length === 1) {
            setUsersOptions([
                {
                    value: userServices[0].username,
                },
            ]);
            setUserSelected(userServices[0].username);
            onSelectUser(username, 0);
            return;
        }
        setUsersOptions(
            userServices?.map(user => ({
                value: user.username,
            })) || []
        );
    }, [userServices]);

    // handle autocomplete for services
    const onSearchServices = React.useCallback(
        async (val: string | undefined) => {
            try {
                const queryParams: SearchQueryParams = {
                    page: 1,
                    pageSize: 10,
                    searchWord: val,
                    sortBy: Direction.ASC,
                    searchColum: 'Name',
                };
                const newPayload = removeUndefinedAttribute(queryParams);
                const response = await getServicePaging(newPayload);
                if (response.status === 200) {
                    const data = response.data?.data ?? [];
                    setServiceList(data);
                    const options = data.map(item => ({
                        value: item.name,
                    }));
                    setServicesOptions(options);
                }
            } catch (error) {
                // Handle error
            }
        },
        []
    );

    const handleSearchServices = (value: string) => {
        onSearchKeywordServices(value);
    };

    const onSearchKeywordServices = React.useMemo(() => {
        return debouce(onSearchServices, 600);
    }, [onSearchServices]);

    React.useEffect(() => {
        return () => {
            onSearchKeywordServices.cancel();
        };
    }, [onSearchKeywordServices]);
    // end handle autocomplete for services

    // handle autocomplete for users
    const onSearchUsers = React.useCallback(async (val: string | undefined) => {
        try {
            const queryParams: SearchQueryParams = {
                page: 1,
                pageSize: 10,
                searchWord: val,
                sortBy: Direction.ASC,
                searchColum: 'Username',
            };
            const newPayload = removeUndefinedAttribute(queryParams);
            const response = await getUsersPaging(newPayload);
            if (response.status === 200) {
                const data = response.data?.data ?? [];
                setUserServices(data);
                const options = data.map(item => ({
                    value: item.username,
                }));
                setUsersOptions(options);
            }
        } catch (error) {
            // Handle error
        }
    }, []);

    const handleSearchUsers = (value: string) => {
        onSearchKeywordUsers(value);
    };

    const onSearchKeywordUsers = React.useMemo(() => {
        return debouce(onSearchUsers, 600);
    }, [onSearchUsers]);

    React.useEffect(() => {
        return () => {
            onSearchKeywordUsers.cancel();
        };
    }, [onSearchKeywordUsers]);
    // end handle autocomplete for users

    // init data
    React.useEffect(() => {
        onSearchServices(undefined);
        if (isUserRole) {
            onSearchUsers(username);
            setUserSelected(username);
            onSelectUser(username, 0);
        } else {
            onSearchUsers(undefined);
        }
    }, []);

    return (
        <Wrapper className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border py-[10px] px-[5px]">
            <div className="flex flex-col w-full gap-[10px]">
                <div className="flex flex-col items-start gap-[20px]">
                    <FormWrapper className="flex flex-col gap-[10px] w-full items-center">
                        <Form.List name="services">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(
                                        (
                                            { key, name, ...restField },
                                            index
                                        ) => (
                                            <Space
                                                key={key}
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                    marginBottom: 5,
                                                    maxWidth: 600,
                                                }}
                                                align="center"
                                            >
                                                <span className="flex items-center mr-3 text-center">
                                                    {index + 1}.
                                                </span>
                                                <FieldWrapper>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'name']}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Please select Service Name',
                                                            },
                                                        ]}
                                                    >
                                                        <AutoComplete
                                                            options={
                                                                servicesOptions
                                                            }
                                                            className="w-full"
                                                            size="large"
                                                            showSearch
                                                            value={userSelected}
                                                            popupClassName="certain-category-search-dropdown"
                                                            placeholder="Service Name"
                                                            onSelect={value => {
                                                                onSelectedService(
                                                                    value,
                                                                    index
                                                                );
                                                            }}
                                                            onSearch={
                                                                handleSearchServices
                                                            }
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        name={[
                                                            name,
                                                            'assignee',
                                                        ]}
                                                    >
                                                        <AutoComplete
                                                            options={
                                                                usersOptions
                                                            }
                                                            className="w-full"
                                                            size="large"
                                                            showSearch
                                                            allowClear
                                                            popupClassName="certain-category-search-dropdown"
                                                            placeholder="Assignee"
                                                            onSelect={value => {
                                                                onSelectUser(
                                                                    value,
                                                                    index
                                                                );
                                                            }}
                                                            onClear={() =>
                                                                onClearCar(
                                                                    index
                                                                )
                                                            }
                                                            onSearch={
                                                                handleSearchUsers
                                                            }
                                                        />
                                                    </Form.Item>
                                                </FieldWrapper>
                                                <MinusCircleOutlined
                                                    onClick={() => {
                                                        remove(name);
                                                        onRemoveService(index);
                                                    }}
                                                />
                                            </Space>
                                        )
                                    )}
                                    <Form.Item className="max-md:px-[44px]">
                                        <Button
                                            type="dashed"
                                            onClick={() => {
                                                isUserRole
                                                    ? add({
                                                          name: '',
                                                          assignee: username,
                                                      })
                                                    : add({
                                                          name: '',
                                                          assignee: '',
                                                      });
                                            }}
                                            block
                                            icon={<PlusOutlined />}
                                        >
                                            Add Service
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </FormWrapper>
                </div>
            </div>
        </Wrapper>
    );
};
