import { MenuProps } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { DesktopSider } from './desktop';
import { MobileSider } from './mobile';
import { ICAllTask, ICCategory, ICTodoList, ICUser } from '../../../icons';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { Role } from '../../../utils/constants';

const Wrapper = styled.div`
    .ant-menu-item,
    .ant-menu-submenu-title {
        gap: 0.5rem;
    }
`;

type MenuItem = Required<MenuProps>['items'][number];

type SiderAdminProps = {
    collapsed: boolean;
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SiderAdmin: React.FC<SiderAdminProps> = ({
    collapsed,
    setCollapsed,
}) => {
    const size = useScreenSize();
    const mobileMode = size === 'xs' || size === 'sm';
    const navigate = useNavigate();
    const [selectedMenu, setSelectedMenu] = React.useState('');
    const [openKeys, setOpenKeys] = React.useState(['']);
    const userInfo = useSelector((state: RootState) => state.userInfo);

    const items: MenuItem[] = React.useMemo(() => {
        const isManagerRole = userInfo?.roles?.includes(Role.MANAGER);
        if (!isManagerRole) {
            return [
                {
                    key: 'todo_list',
                    icon: (
                        <span
                            role="img"
                            aria-label="user"
                            className="anticon anticon-user ant-menu-item-icon"
                        >
                            <ICTodoList width={20} height={20} />
                        </span>
                    ),
                    label: 'My To-do list',
                },
                {
                    key: 'add_receipts',
                    icon: (
                        <span
                            role="img"
                            aria-label="user"
                            className="anticon anticon-user ant-menu-item-icon"
                        >
                            <ICUser width={20} height={20} />
                        </span>
                    ),
                    label: 'Add Receipt',
                },
            ];
        }
        return [
            {
                key: 'todo_list',
                icon: (
                    <span
                        role="img"
                        aria-label="user"
                        className="anticon anticon-user ant-menu-item-icon"
                    >
                        <ICTodoList width={20} height={20} />
                    </span>
                ),
                label: 'My To-do list',
            },
            {
                key: 'all_tasks',
                icon: (
                    <span
                        role="img"
                        aria-label="user"
                        className="anticon anticon-user ant-menu-item-icon"
                    >
                        <ICAllTask width={20} height={20} />
                    </span>
                ),
                label: 'All Tasks',
            },
            {
                key: 'add_receipts',
                icon: (
                    <span
                        role="img"
                        aria-label="user"
                        className="anticon anticon-user ant-menu-item-icon"
                    >
                        <ICUser width={20} height={20} />
                    </span>
                ),
                label: 'Add Receipt',
            },
            {
                key: 'settings',
                icon: (
                    <span
                        role="img"
                        aria-label="user"
                        className="anticon anticon-user ant-menu-item-icon"
                    >
                        <ICCategory width={20} height={20} />
                    </span>
                ),
                label: 'Categories',
                children: [
                    {
                        key: 'cars',
                        label: 'Cars',
                    },
                    {
                        key: 'services',
                        label: 'Services',
                    },
                    {
                        key: 'users',
                        label: 'Users',
                    },
                ],
            },
        ];
    }, [userInfo]);

    const onClickMenu: MenuProps['onClick'] = ({ key }) => {
        setSelectedMenu(key);
        setCollapsed(false);
        if (key === 'all_tasks') {
            navigate('/tasks');
        }
        if (key === 'todo_list') {
            navigate('/todo-list');
        }
        if (key === 'start_service') {
            navigate('/start-service');
        }
        if (key === 'add_receipts') {
            navigate('/add-receipts');
        }
        if (key === 'cars') {
            navigate('/settings/cars');
        }
        if (key === 'services') {
            navigate('/settings/services');
        }
        if (key === 'users') {
            navigate('/settings/user-service');
        }
    };

    React.useEffect(() => {
        const path = window.location.pathname;
        setSelectedMenu('');
        if (path.includes('/tasks')) {
            setSelectedMenu('all_tasks');
        }
        if (path.includes('/todo-list')) {
            setSelectedMenu('todo_list');
        }
        if (path === '/start-service') {
            setSelectedMenu('start_service');
        }
        if (path === '/add-receipts') {
            setSelectedMenu('add_receipts');
        }
        if (path === '/settings/cars') {
            setSelectedMenu('Cars');
        }
        if (path === '/settings/services') {
            setSelectedMenu('Services');
        }
        if (path === '/settings/user-service') {
            setSelectedMenu('Users');
        }
    }, []);

    React.useEffect(() => {
        if (!items.find((item: any) => item.key === window.location.pathname)) {
            const currentSubItem = items.find((item: any) =>
                item.children?.find((item: any) => {
                    return window.location.pathname.includes(item.key);
                })
            ) as any;
            if (currentSubItem) {
                setOpenKeys([(currentSubItem['key'] as string) ?? '']);
                if (currentSubItem && currentSubItem['children']) {
                    const currentSubItemKey = currentSubItem['children']?.find(
                        (item: any) => {
                            return window.location.pathname.includes(item.key);
                        }
                    );
                    if (currentSubItemKey) {
                        setSelectedMenu(
                            (currentSubItemKey['key'] as string) ?? ''
                        );
                    }
                }
            }
            return;
        }
        setOpenKeys([]);
    }, [items]);

    return (
        <Wrapper className="flex">
            {mobileMode ? (
                <MobileSider
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    openKeys={openKeys}
                    items={items}
                    selectedMenu={selectedMenu}
                    onClickMenu={onClickMenu}
                    setOpenKeys={setOpenKeys}
                />
            ) : (
                <DesktopSider
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    openKeys={openKeys}
                    items={items}
                    selectedMenu={selectedMenu}
                    onClickMenu={onClickMenu}
                    setOpenKeys={setOpenKeys}
                />
            )}
        </Wrapper>
    );
};
