import React from 'react';
import {
    PriorityColorMapping,
    PriorityGradientColorMapping,
    PriorityOptionsMapping,
    TCarInService,
} from '../start-new-task/type';
import { getDownloadURL, ref } from 'firebase/storage';
import { storageFirebase } from '../../firebase/firebaseConfig';
import styled from 'styled-components';
import { Tag } from 'antd';
import dayjs from 'dayjs';
import { ClockCircleTwoTone } from '@ant-design/icons';

const Wrapper = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    overflow: hidden;
    padding: 0px;
`;

type CarPhotosProps = {
    currentTask: TCarInService | undefined;
};

export const CarPhotos: React.FC<CarPhotosProps> = ({ currentTask }) => {
    const [currentImagePath, setCurrentImagePath] = React.useState<string>('');

    const priorityText = React.useMemo(() => {
        if (currentTask?.priority) {
            return PriorityOptionsMapping[currentTask?.priority];
        }
        return '';
    }, [currentTask]);

    const priorityColor = React.useMemo(() => {
        if (currentTask?.priority) {
            return PriorityColorMapping[currentTask?.priority];
        }
        return '';
    }, [currentTask]);

    const completedByValue = React.useMemo(() => {
        return currentTask?.completedBy
            ? dayjs(currentTask?.completedBy).format('DD/MM/YYYY HH:mm')
            : '';
    }, [currentTask]);

    const convertImageUrl = async (pathTemp: string) => {
        const imageRef = ref(storageFirebase, pathTemp);
        const url = await getDownloadURL(imageRef);
        return url;
    };

    React.useEffect(() => {
        if (currentTask?.car?.carImage?.imageUrl) {
            const imageUrlTemp = currentTask.car.carImage.imageUrl;
            convertImageUrl(imageUrlTemp).then(url => {
                setCurrentImagePath(url);
            });
        }
    }, [currentTask?.car]);

    return currentImagePath ? (
        <div className="flex flex-col relative h-full w-full rounded-t-[12px] rounded-b-[0px] box-border overflow-hidden">
            <div className="flex w-full absolute top-[10px] left-0 px-[16px] z-[5] items-center justify-between">
                <Tag color={priorityColor}>{priorityText}</Tag>
                {completedByValue && (
                    <div
                        className="flex gap-1 items-center justify-center py-[5px] px-[10px] rounded-lg"
                        style={{
                            background: currentTask?.priority
                                ? PriorityGradientColorMapping[
                                      currentTask?.priority
                                  ]
                                : 'white',
                        }}
                    >
                        <ClockCircleTwoTone />
                        <span className="text-muted text-xs">
                            {completedByValue}
                        </span>
                    </div>
                )}
            </div>
            <Wrapper className="aspect-[16/9] object-cover sm:aspect-[2/1] lg:aspect-[3/2] flex box-border overflow-hidden">
                <img
                    width="100%"
                    height="100%"
                    src={currentImagePath}
                    style={{
                        objectFit: 'cover',
                        borderRadius: '0',
                        height: '100%',
                    }}
                    alt={`image-${0}`}
                />
            </Wrapper>
        </div>
    ) : (
        <></>
    );
};
