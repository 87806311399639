import React from 'react';
import { useForm } from 'antd/lib/form/Form';
import { Form, notification as Notification } from 'antd';
import { AutoTrackButton } from '../../../components/button';
import { AutoTrackCheckBox } from '../../../components/form/checkbox';
import { AutoTrackForm } from '../../../components/form/form';

import { signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword } from 'firebase/auth';

import {
    AutoTrackInput,
    AutoTrackInputPassword,
} from '../../../components/form/input';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/slices/appInfo';
import styled from 'styled-components';
import { formatSearch } from '../../../utils/formatSearch';
import { ApiInstance } from '../../../api';
import { setUserInfo, TUserInfo } from '../../../redux/slices/userInfo';
import { useNavigate } from 'react-router-dom';
import { ICCheckFill, ICClose, ICWarning } from '../../../icons';
import { auth } from '../../../firebase/firebaseConfig';
import { checkEmailExists } from '../../../api/auth';
import { getUserInfo } from '../../../api/setting/users';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const Wrapper = styled.div`
    background-color: #7357ff;
    background-image: url('/images/bg-pattern.png');
    background-size: cover;
    background-repeat: no-repeat;
`;

type FieldType = {
    email?: string;
    password?: string;
    remember?: string;
};

export const LoginPage: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loginForm] = useForm();
    const [api, contextHolder] = Notification.useNotification();
    const openNotification = (
        type: NotificationType,
        title: React.ReactNode,
        description?: React.ReactNode
    ) => {
        if (type === 'success') {
            api[type]({
                icon: <ICCheckFill />,
                message: (
                    <span className="text-high-em text-body-bold">{title}</span>
                ),
                description,
                placement: 'bottom',
                className: 'middle-notification',
                closeIcon: <ICClose height={18} width={18} fill={'#4F4B5C'} />,
            });
        }
        if (type === 'error') {
            return api[type]({
                icon: <ICWarning fill="#F03D3D" />,
                message: (
                    <span className="text-body-bold text-high-em">{title}</span>
                ),
                description,
                placement: 'bottom',
                className: 'middle-notification',
                closeIcon: <ICClose height={18} width={18} fill={'#4F4B5C'} />,
            });
        }
    };

    const onFinished = React.useCallback(async () => {
        try {
            dispatch(setLoading(true));
            const emailForm = loginForm.getFieldValue('email') || '';
            const email = emailForm.toLowerCase();
            const password = loginForm.getFieldValue('password');
            const userCredential = await firebaseSignInWithEmailAndPassword(
                auth,
                email,
                password
            );
            if (userCredential.user) {
                localStorage.clear();
                const newUserInfo: TUserInfo = {
                    Id: userCredential.user.uid,
                    PhoneNumber: userCredential.user.phoneNumber,
                    UserIdentifier: userCredential.user.uid,
                    Email: userCredential.user.email,
                };
                const tokenResult =
                    await userCredential.user.getIdTokenResult();
                ApiInstance.setToken(tokenResult.token);
                newUserInfo.token = tokenResult.token;
                const userInfoResponse = await getUserInfo(email);
                if (userInfoResponse.status === 200) {
                    const userInfoResponseData = userInfoResponse.data;
                    if (userInfoResponseData?.isChangedPassword === false) {
                        navigate(`/change-password/${email}`);
                        return;
                    } else {
                        dispatch(setUserInfo(newUserInfo));
                        localStorage.setItem(
                            'userInfo',
                            JSON.stringify(newUserInfo)
                        );
                        localStorage.setItem('token', tokenResult.token);
                        localStorage.setItem(
                            'refreshToken',
                            userCredential.user.refreshToken || ''
                        );
                        setTimeout(() => {
                            const search = formatSearch(location.search);
                            const from = search.from || '/todo-list';
                            navigate(from);
                        }, 5);
                    }
                }
            }
        } catch (error: any) {
            handleErrorFirebase();
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, openNotification, navigate]);

    const handleErrorFirebase = async () => {
        const email = loginForm.getFieldValue('email');
        try {
            const responseEmailExists = await checkEmailExists(email);
            if (responseEmailExists?.status === 200) {
                openNotification(
                    'error',
                    'Login failed',
                    'Please check your email and password'
                );
                return;
            }
            navigate(`/change-password/${email}`);
        } catch (error) {
            navigate(`/change-password/${email}`);
        }
    };

    return (
        <Wrapper className="flex w-screen h-screen items-center justify-center bg-surface-low">
            {contextHolder}
            <div className="shadow-e-03 max-md:max-w-[478px] max-md:w-auto w-[478px] p-[32px] flex flex-col gap-[24px] rounded-[12px] overflow-hidden box-border bg-white">
                <div className="flex justify-center">
                    <h1 className="text-heading-5 text-high-em font-semibold">
                        Welcome Auto Track
                    </h1>
                </div>
                <AutoTrackForm
                    form={loginForm}
                    name="loginForm"
                    layout="vertical"
                    labelCol={{ span: 8 }}
                    onFinish={onFinished}
                    childNode={
                        <>
                            <div className="form flex flex-col w-full gap-[16px]">
                                <div>
                                    <Form.Item<FieldType>
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message:
                                                    'Please input your email!',
                                            },
                                        ]}
                                    >
                                        <AutoTrackInput
                                            size="large"
                                            maxLength={50}
                                            placeholder="Email"
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item<FieldType>
                                        label="Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please input your password!',
                                            },
                                        ]}
                                    >
                                        <AutoTrackInputPassword
                                            size="large"
                                            maxLength={20}
                                            placeholder="Password"
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="flex flex-col w-full gap-[24px] pt-[16px]">
                                <div className="flex items-center justify-between">
                                    <AutoTrackCheckBox className="purple-checkbox">
                                        <span className="text-body text-high-em">
                                            Remember me
                                        </span>
                                    </AutoTrackCheckBox>
                                </div>
                                <div>
                                    <AutoTrackButton htmlType="submit">
                                        Login
                                    </AutoTrackButton>
                                </div>
                                {/* <div className="flex gap-[16px] items-center justify-center text-center text-high-em text-body">
                                    <span>You don't have an account?</span>
                                    <Link
                                        to="/register"
                                        className="underline text-info-500 font-medium"
                                    >
                                        Sign up
                                    </Link>
                                </div> */}
                            </div>
                        </>
                    }
                />
            </div>
        </Wrapper>
    );
};
