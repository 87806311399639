import React from 'react';
import { AutoTrackButton } from '../../../../components/button';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { useCreateServices } from '../../hooks/useCreateServices';
import {
    EPriorityCarInService,
    EPriorityCarInServiceOptions,
    EStatusCarInService,
    StatusOptions,
    TCarInServiceRequest,
    TServiceRequest,
} from '../../type';
import { ReviewServiceRow } from './row-service';
import { reorder } from '../../../../utils';
import { saveCarInService } from '../../../../api/car-in-service';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../../redux/slices/appInfo';
import { useNotification } from '../../../../hooks/useNotification';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Modal, Segmented } from 'antd';
import { DatetimeControl } from '../../../components/datetime-control';
import { CalendarOutlined } from '@ant-design/icons';
import { RootState } from '../../../../redux/reducers';

const WrapperSegmented = styled.div`
    .active-primary {
        .ant-segmented-item-selected {
            background-color: var(--primaryColor);
            color: white;
        }
    }
    .ant-segmented {
        @media (max-width: 768px) {
            width: 100%;
            .ant-segmented-item {
                min-width: auto !important;
            }
        }
    }
    .ant-segmented .ant-segmented-item {
        min-width: 100px;
    }
`;

const WrapperActions = styled.div`
    @media (max-width: 768px) {
        .ant-btn {
            width: 100% !important;
        }
    }
`;

const WrapperDatetime = styled.div`
    .ant-picker {
        min-width: 200px;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
`;

const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : 'white',
    width: '100%',
});
const getMoveItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    background: isDragging ? '#F1E8FD' : 'white',
    color: isDragging ? '#46485C' : 'inherit',
    borderRadius: isDragging ? '8px' : 'inherit',
    border: isDragging ? '1px solid #7357FF' : 'inherit',
    boxShadow: isDragging ? '0px 4px 16px rgba(79, 117, 140, 0.24)' : 'inherit',
    ...draggableStyle,
});

export const PreviewStep: React.FC = () => {
    const {
        carSelected,
        reviewServices,
        setReviewServices,
        setServicesSelected,
        onChangeStep,
        setRepairServices,
        dateTimeSelection,
        setDateTimeSelection,
    } = useCreateServices();
    const navigate = useNavigate();
    const { showSuccess, showError } = useNotification();
    const dispatch = useDispatch();
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const [prioritySelectedControl, setPrioritySelectedControl] =
        React.useState<EPriorityCarInService>(EPriorityCarInService.MEDIUM);
    const priorityOptions = EPriorityCarInServiceOptions.map(option => ({
        ...option,
    }));
    const [statusSelectedControl, setStatusSelectedControl] =
        React.useState<EStatusCarInService>(EStatusCarInService.PENDING);
    const statusOptions = StatusOptions.map(option => ({
        ...option,
    }));
    const [openDatetime, setOpenDatetime] = React.useState(false);

    React.useEffect(() => {
        const nowDate = new Date();
        const currentMonth = `${
            nowDate.getMonth() + 1 < 10
                ? `0${nowDate.getMonth() + 1}`
                : nowDate.getMonth() + 1
        }`;
        const currentDay = `${
            nowDate.getDate() < 10 ? `0${nowDate.getDate()}` : nowDate.getDate()
        }`;
        const currentDate = `${nowDate.getFullYear()}-${currentMonth}-${currentDay}`;
        const currentHour = `${
            nowDate.getHours() < 10
                ? `0${nowDate.getHours()}`
                : nowDate.getHours()
        }`;
        const currentMinute = `${
            nowDate.getMinutes() < 10
                ? `0${nowDate.getMinutes()}`
                : nowDate.getMinutes()
        }`;
        const currentTime = `${currentHour}:${currentMinute}`;
        const initValueCompletedBy = `${currentDate} ${currentTime}`;
        setDateTimeSelection(prev => prev || initValueCompletedBy);
    }, []);

    const onDragEnd = (result: any) => {
        const { source, destination } = result;
        if (!result.destination) {
            return;
        }
        const newData = reorder(
            reviewServices,
            source.index,
            destination.index
        );
        setReviewServices(newData);
        setRepairServices(newData);
    };

    const onDeleteService = (index: number) => {
        const currentService = reviewServices?.[index];
        const newRepairServices = reviewServices?.filter((_, i) => i !== index);
        setReviewServices(newRepairServices);
        setServicesSelected(prev => {
            if (prev) {
                return prev.filter(
                    item => item.serviceId !== currentService?.serviceId
                );
            }
            return prev;
        });
    };

    const reviewServiceList = React.useMemo(() => {
        if (
            !reviewServices ||
            (reviewServices && reviewServices?.length === 0)
        ) {
            return null;
        }
        return reviewServices
            .filter(d => d !== undefined)
            .map((item, index) => {
                return (
                    <Draggable
                        key={`${index}_${item.serviceId}`}
                        draggableId={`id_${item.serviceId}_${index}`}
                        index={index}
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getMoveItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                )}
                            >
                                <ReviewServiceRow
                                    serviceData={item}
                                    index={index}
                                    key={item.serviceId}
                                    onDelete={onDeleteService}
                                />
                            </div>
                        )}
                    </Draggable>
                );
            });
    }, [reviewServices]);

    const onSubmit = React.useCallback(async () => {
        try {
            if (dateTimeSelection === '') {
                showError('Save service failed', 'Please select date');
                return;
            }
            dispatch(setLoading(true));
            const newServices = reviewServices?.map(item => ({
                serviceId: item.serviceId,
                userId: item.users?.length ? item.users[0].userId : undefined,
                saveCarInService: EStatusCarInService.PENDING,
            }));
            const date = new Date(dateTimeSelection);
            const newDateTimeSelection = date.toISOString();
            const payload: TCarInServiceRequest = {
                carId: carSelected?.carId,
                services: newServices as TServiceRequest[],
                status: statusSelectedControl,
                priority: prioritySelectedControl,
                completedBy: newDateTimeSelection,
                createdBy: userInfo?.userId,
            };
            const result = await saveCarInService(payload);
            if (result.status === 201) {
                showSuccess('Save service success', 'Service has been created');
                navigate('/');
            }
        } catch (error) {
            // Handle error
            showError('Save service failed', 'Service has not been created');
        } finally {
            dispatch(setLoading(false));
        }
    }, [
        dispatch,
        reviewServices,
        carSelected,
        showSuccess,
        showError,
        navigate,
        statusSelectedControl,
        prioritySelectedControl,
        dateTimeSelection,
        userInfo,
    ]);

    const onSelectDate = (date: string) => {
        setDateTimeSelection(date);
        setOpenDatetime(false);
    };

    return (
        <div className="flex w-full gap-[30px] flex-wrap flex-col">
            <div className="bg-white max-md:p-[16px] p-[32px] rounded-[8px]">
                <div className="max-md:mb-[5px] mb-[24px] flex flex-col gap-[8px]">
                    <div className="text-heading-6-bold text-high-em">
                        Car Information
                    </div>
                    <div className="text-med-em text-body">
                        Please review the car information that you want to add
                        to the system
                    </div>
                </div>
                <div className="flex flex-col gap-[10px] w-full">
                    <div className="grid max-md:grid-cols-1 grid-cols-[110px_1fr] items-center w-full gap-1">
                        <span className="text-low-em">Name</span>
                        <span>
                            {carSelected?.licensePlate} | {carSelected?.make}
                        </span>
                    </div>
                    <div className="grid max-md:grid-cols-1 grid-cols-[110px_1fr] items-center w-full gap-1">
                        <span className="text-low-em">Status</span>
                        <WrapperSegmented>
                            <Segmented
                                className="active-primary"
                                type="primary"
                                value={statusSelectedControl}
                                options={statusOptions}
                                disabled
                                onChange={status => {
                                    setStatusSelectedControl(status);
                                }}
                            />
                        </WrapperSegmented>
                    </div>
                    <div className="grid max-md:grid-cols-1 grid-cols-[110px_1fr] items-center w-full gap-1">
                        <div className="text-low-em">Priority</div>
                        <WrapperSegmented>
                            <Segmented
                                className="active-primary"
                                type="primary"
                                value={prioritySelectedControl}
                                options={priorityOptions}
                                onChange={status => {
                                    setPrioritySelectedControl(status);
                                }}
                            />
                        </WrapperSegmented>
                    </div>
                    <div className="grid max-md:grid-cols-1 grid-cols-[110px_1fr] items-center w-full gap-1">
                        <div className="text-low-em">Completed by</div>
                        <WrapperDatetime
                            className="flex cursor-pointer max-md:w-full w-[180px]"
                            onClick={() => setOpenDatetime(true)}
                        >
                            <div className="flex w-full p-[10px] items-center justify-between bg-gray-100 border border-gray-300 hover:border-primary rounded-[6px]">
                                <div className="flex items-center text-center text-[13px] text-gray-700">
                                    {dateTimeSelection || 'Select date'}
                                </div>
                                <CalendarOutlined className="hover:text-primary" />
                            </div>
                        </WrapperDatetime>
                    </div>
                </div>
            </div>

            <div className="bg-white max-md:p-[16px] p-[32px] rounded-[8px]">
                <div className="max-md:mb-[5px] mb-[24px] flex flex-col gap-[8px]">
                    <div className="text-heading-6-bold text-high-em">
                        Preview services
                    </div>
                    <div className="text-med-em text-body">
                        Please review the services that you want to add to the
                        system
                    </div>
                </div>
                <div className="grid grid-cols-1 max-md:mt-[0] mt-[24px] gap-[12px] rounded-[12px] py-[16px] bg-white">
                    <div className="grid max-md:grid-cols-[1fr_1fr] grid-cols-[50px_1fr_250px] justify-start items-center max-md:pl-[40px] pl-[55px] font-bold text-[16px] leading-[19px]">
                        <span className="max-md:hidden">No.</span>
                        <span>Service</span>
                        <span>Assign</span>
                    </div>
                    {reviewServices && reviewServices?.length > 0 && (
                        <div>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(
                                                snapshot.isDraggingOver
                                            )}
                                        >
                                            {reviewServiceList}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    )}
                    <WrapperActions className="flex max-md:grid max-md:grid-cols-2 justify-end gap-[10px] mt-5">
                        <span>
                            <AutoTrackButton
                                btnSize="md"
                                btnType="outline"
                                classess="max-md:w-full"
                                style={{ width: '100px' }}
                                onClick={() => onChangeStep(1)}
                            >
                                Back
                            </AutoTrackButton>
                        </span>
                        <span>
                            <AutoTrackButton
                                type="primary"
                                btnSize="md"
                                btnType="primary"
                                classess="max-md:w-full"
                                style={{ width: '100px' }}
                                onClick={onSubmit}
                            >
                                Submit
                            </AutoTrackButton>
                        </span>
                    </WrapperActions>
                </div>
            </div>

            <Modal
                title="Select Completed by"
                open={openDatetime}
                destroyOnClose
                maskClosable={false}
                onCancel={() => setOpenDatetime(false)}
                footer={null}
                centered
            >
                <DatetimeControl
                    onSelectDate={onSelectDate}
                    initValue={dateTimeSelection}
                />
            </Modal>
        </div>
    );
};
